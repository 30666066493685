// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

import {
  Anchor, Box, Main, Notification,
} from 'grommet';
import { FormPreviousLink } from 'grommet-icons';
import {
  useCurrencyQuery,
  useCustomerQuery,
  useGetServiceQuery,
} from '../../../core';
import {
  usePermissionChecker,
  useRoleChecker,
} from '../../shared/hooks';
import UserStore from '../../stores/UserStore';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import Loader from '../../shared/loader';
import ServiceEdit from './ServiceEdit';
import { ServiceEditorContext, getNewPermissions, useServiceEditorContext } from './contexts/ServiceEditorContext';

const ServiceEditContainer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const queryClient = useQueryClient();
  const { hasPermissions } = usePermissionChecker();
  const { isOneOfRoles } = useRoleChecker();
  const { customerId, serviceId } = params;
  const location = useLocation();
  const { state } = location;
  const tenant = state?.tenant || 'MASTER';
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(undefined);
  const me = useMemo(() => UserStore.getUser(), []);
  const [editorState, setEditorState] = useState({
    customer: null,
    permissions: {
      asmRole: undefined,
      userType: undefined,
      canReadOptions: false,
      canEditOptions: false,
      canReadMappings: false,
      canEditMappings: false,
      canReadRates: false,
      canEditRates: false,
      canMarkupRates: false,
    },
    context: { tenant: 'MASTER' },
    options: null,
    originalOptions: null,
    equipment: {
      isFetched: false,
      didInvalidate: true,
      items: [],
    },
    meters: null,
    rates: null,
    locationRates: null,
    dirtyEquipment: [],
    dirtyComponents: [],
    dirtyState: {
      options: false,
      resources: false,
      rates: false
    },
    validation: {
      isFetching: false, didInvalidate: true, results: undefined, errors: undefined
    }
  });

  const {
    isFetching: isLoadingCustomer,
    data: customer
  } = useCustomerQuery(customerId, {
    onError: (error) => {
      setResponse({
        status: 'critical',
        message: `Unable to load customer: ${error || ''}`,
        stacktraceRef: undefined,
      });
      setLoading(false);
    },
  });

  useEffect(() => {
    // clear any cached data:
    queryClient.removeQueries({ queryKey: ['GLBM:EQUIPMENT-LIST', customerId, serviceId] });
  }, []);

  useEffect(() => {
    setEditorState(state => ({
      ...state,
      permissions: getNewPermissions(state.permissions, {
        user: me,
        isOneOfRoles,
        customer,
        hasPermissions,
        serviceId,
        tenant,
      }),
      context: { tenant },
    }));
  }, [customer, hasPermissions, isOneOfRoles, me, serviceId, tenant]);

  const {
    data: currencyData,
    error: currencyError,
  } = useCurrencyQuery(customer ? (customer.contractCurrency || 'USD') : undefined);

  const {
    isError: isServiceError,
    error: serviceError,
    isSuccess: isServiceSuccess,
    data: serviceData,
    refetch: _getOptions
  } = useGetServiceQuery(customer?.id, serviceId, {
    enabled: false,
  });

  useEffect(() => {
    if (isServiceSuccess && serviceData) {
      const options = JSON.parse(JSON.stringify(serviceData));
      if (!options.config.hasOwnProperty('serviceType')) {
        options.config.serviceType = options.serviceType;
      }
      const originalOptions = JSON.parse(JSON.stringify(options));
      if (options.status === 'NEW') {
        options.status = 'INCOMPLETE';
      }
      setEditorState(prev => ({ ...prev, options, originalOptions }));
      setLoading(false);
    }
  }, [isServiceSuccess, serviceData]);

  useEffect(() => {
    if (isServiceError && serviceError) {
      setLoading(false);
      setResponse({
        status: 'critical',
        message: `Unable to load service: ${serviceError?.response?.data?.message || serviceError?.message}`,
        stacktraceRef: undefined
      });
    }
  }, [isServiceError, serviceError]);

  useEffect(() => {
    if (currencyData) {
      const newCustomer = { ...customer, preferences: currencyData };
      if (!isEqual(customer, newCustomer)) {
        setEditorState(prev => ({ ...prev, customer: newCustomer }));
        _getOptions();
      }
    }
    if (currencyError) {
      console.error(currencyError);
      setResponse({
        status: 'critical',
        message: `Unable to load customer currency: ${currencyError.message}`,
        stacktraceRef: undefined,
      });
      setLoading(false);
    }
  }, [currencyData, currencyError, customer]);

  // If they click Back on the first step
  const _cancel = () => {
    navigate(-1);
  };

  const _getLoadingContent = () => (
    <Main
      direction='column'
      appCentered={true}
      size='full'
      justify='center'
    >
      <Box
        direction='row'
        gap='small'
        justify='center'
      >
        <Loader text='Loading. Please wait ...' />
      </Box>
    </Main>
  );

  const _getResponseContent = () => {
    const serviceTypeEnum = ServiceTypeStore.getService(serviceId);
    return (
      <Box
        fill={false}
        direction='column'
        size='full'
        justify='center'
      >
        <Notification
          toast={true}
          state={response.stacktraceRef}
          title={response.message}
          message={`Unable to show Service Configuration Wizard for ${serviceTypeEnum.displayName} service`}
          status={response.status}
        />
        <Box
          direction='row'
          pad='medium'
          justify='start'
        >
          <Anchor animateIcon={true} primary={true} onClick={_cancel} icon={<FormPreviousLink size='xxlarge' color='brand' />}>Back to services</Anchor>
        </Box>
      </Box>
    );
  };
  if (loading) {
    return _getLoadingContent();
  }
  if (response) {
    return _getResponseContent();
  }
  return (
    <ServiceEditorContext.Provider value={[editorState, setEditorState]}>
      <ServiceEdit location={location} navigate={navigate} params={params} />
    </ServiceEditorContext.Provider>
  );
};

export default ServiceEditContainer;
