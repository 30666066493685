// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  Box, Button, CheckBoxGroup, Footer, Paragraph,
} from 'grommet';
import moment from 'moment';
import DateRange from '../shared/component/DateRange';
import GLBMLayer from '../shared/component/GLBMLayer';
import TypeLabel from '../shared/component/TypeLabel';
import IDUtil from '../shared/util/IDUtil';
import GLBMFilterPropertyBox from '../shared/component/GLBMFilterPropertyBox';
import { StatusIcon } from '../shared/component/StatusIcon';
import StatusLabel from '../shared/component/StatusLabel';

const JobsFilter = ({
  filter: initialFilter,
  onClose = null,
  onChange = null,
}) => {
  const [filter, setFilter] = useState({
    customerId: initialFilter.customerId,
    from: initialFilter.from,
    to: initialFilter.to,
    type: (Array.isArray(initialFilter.type)) ? [].concat(initialFilter.type) : undefined,
    status: (Array.isArray(initialFilter.status)) ? [].concat(initialFilter.status) : undefined,
  });

  const onChangeOption = (name, event) => {
    // name = name;
    const modifiedFilter = { ...filter };
    if (event.value.length === 0 || event.value[event.value.length - 1] === 'all') {
      delete modifiedFilter[name];
    } else {
      modifiedFilter[name] = event.value.filter(el => el !== 'all');
    }
    setFilter(modifiedFilter);
  };

  const setFromDate = (value) => {
    const modifiedFilter = { ...filter };
    modifiedFilter.from = value;
    setFilter(modifiedFilter);
  };

  const setToDate = (value) => {
    const modifiedFilter = { ...filter };
    modifiedFilter.to = value;
    setFilter(modifiedFilter);
  };

  const onSubmit = () => {
    if (filter.from && filter.to) {
      onChange(filter);
    }
  };

  const renderDateRangeWarning = () => {
    const a = moment(filter.from);
    const b = moment(filter.to);
    const difference = a.diff(b, 'days');
    if (Math.abs(difference) >= 7) {
      return (
        <Box direction='row'>
          <StatusIcon value='warning' size='medium' />
          <Box margin={{ left: 'small' }}>
            <Paragraph style={{ marginTop: '0px' }}>
              The selected date range is greater than 7 days. This may cause delay
              or failure to return results. Consider selecting a smaller time
              period
            </Paragraph>
          </Box>
        </Box>
      );
    }
    return null;
  };

  const renderStatusOptions = () => (
    <CheckBoxGroup
      labelKey='label'
      valueKey='value'
      id={IDUtil.getId('StatusChkGroup')}
      value={filter.status ? filter.status : ['all']}
      options={[
        {
          label: <TypeLabel value='all' label='All' />,
          value: 'all',
          id: IDUtil.getId('StatusAll'),
        },
        {
          label: <StatusLabel value='unknown' label='Processing' />,
          value: 'processing',
          id: IDUtil.getId('StatusProcessing'),
        },
        {
          label: <StatusLabel value='ok' label='Succeeded' />,
          value: 'succeeded',
          id: IDUtil.getId('StatusSucceeded'),
        },
        {
          label: <StatusLabel value='critical' label='Failed' />,
          value: 'failed',
          id: IDUtil.getId('StatusFailed'),
        },
        {
          label: <StatusLabel value='critical' label='Terminated' />,
          value: 'terminated',
          id: IDUtil.getId('StatusTerminated'),
        },
        {
          label: <StatusLabel value='critical' label='Aborted' />,
          value: 'aborted',
          id: IDUtil.getId('StatusAborted'),
        },
      ]}
      onChange={event => onChangeOption('status', event)}
    />
  );

  const renderTypeOptions = () => (
    <CheckBoxGroup
      labelKey='label'
      valueKey='value'
      value={filter.type ? filter.type : ['all']}
      id={IDUtil.getId('TypeChkGroup')}
      options={[
        {
          label: <TypeLabel value='all' label='All' />,
          value: 'all',
          id: IDUtil.getId('TypeAll'),
        },
        {
          label: <TypeLabel value='charge' label='Charging' />,
          value: 'charge',
          id: IDUtil.getId('TypeCharging'),
        },
        {
          label: <TypeLabel value='fill' label='Filling' />,
          value: 'fill',
          id: IDUtil.getId('TypeFilling'),
        },
        {
          label: <TypeLabel value='load' label='Loading' />,
          value: 'load',
          id: IDUtil.getId('TypeLoading'),
        },
        {
          label: <TypeLabel value='migrate' label='Migrating' />,
          value: 'migrate',
          id: IDUtil.getId('TypeMigrating'),
        },
        {
          label: <TypeLabel value='other' label='Other' />,
          value: 'other',
          id: IDUtil.getId('TypeOther'),
        },
        {
          label: <TypeLabel value='sync' label='Syncing' />,
          value: 'sync',
          id: IDUtil.getId('TypeSyncing'),
        },
        {
          label: <TypeLabel value='transform' label='Transforming' />,
          value: 'transform',
          id: IDUtil.getId('TypeTransforming'),
        },
      ]}
      onChange={event => onChangeOption('type', event)}
    />
  );

  const onClear = () => {
    const modifiedFilter = { ...filter };
    delete modifiedFilter.type;
    delete modifiedFilter.status;
    setFilter(modifiedFilter);
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      flush={true}
      closer={true}
      overlayClose={false}
      onClose={onClose}
      style={{ minWidth: '520px' }}
      title='Filter Data Processing Jobs'
    >
      <Box flex={true} overflow='auto'>
        <GLBMFilterPropertyBox
          label='Date Range (UTC)'
          contentProps={{ pad: { horizontal: 'small' } }}
          required={true}
        >
          <DateRange
            filter={filter}
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
          {renderDateRangeWarning()}
        </GLBMFilterPropertyBox>
        <GLBMFilterPropertyBox label='Status'>
          {renderStatusOptions()}
        </GLBMFilterPropertyBox>
        <GLBMFilterPropertyBox label='Type'>
          {renderTypeOptions()}
        </GLBMFilterPropertyBox>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Apply'
              type='button'
              id={IDUtil.getId('ApplyBtn')}
              primary={true}
              onClick={onSubmit}
            />
            <Button
              label='Cancel'
              type='button'
              id={IDUtil.getId('CancelBtn')}
              secondary={true}
              onClick={onClose}
            />
          </Box>
          <Button
            label='Clear Filters'
            id={IDUtil.getId('ResetBtn')}
            onClick={onClear}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

JobsFilter.propTypes = {
  filter: PropTypes.shape({
    customerId: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    type: PropTypes.array,
    status: PropTypes.array,
  }).isRequired,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

export default JobsFilter;
