// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import * as Case from 'case';
import {
  Accordion,
  AccordionPanel,
  Box,
  Button, FormField,
  Heading,
  Notification,
  Select,
  Text,
} from 'grommet';
import moment from 'moment';
import {
  useQueryCapacityDownload,
  useQueryCapacityGroups,
  useQueryCapacityOptions,
} from '../../../core';
import ServiceTypeStore from '../../stores/ServiceTypeStore';
import IDUtil from '../../shared/util/IDUtil';
import Loader from '../../shared/loader';
import UserStore from '../../stores/UserStore';
import CapacityPlanningGroup from './CapacityPlanningGroup';
import CapacityPlanningGroupLabel from './CapacityPlanningGroupLabel';
import { VIEWS } from './consts';
import { computeTimePeriodOptions } from './utils';

const getDefaultOptions = () => ({
  locationType: 'ALL',
  serviceType: 'ALL',
  groupBy: 'sys_MeterId_s',
  priorMonths: 2,
  forecastPeriod: 3,
  timePeriodOption: 6,
  outputUnit: 'None',
  view: VIEWS.trend,
  month: moment().subtract(1, 'day').format('MM/YYYY'),
});
const CapacityPlanning = ({ selectedCustomer: { id: selectedCustomerProp, contractStartMonth }, selectedService: selectedServiceProp = undefined }) => {
  const [activeGroups, setActiveGroups] = useState([0]);
  const [selectedCustomer, setSelectedCustomer] = useState(selectedCustomerProp);
  const [selectedService, setSelectedService] = useState(selectedServiceProp);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [options, setOptions] = useState();

  const userId = UserStore.getUser()?.id;
  const optionsStorageKey = `analytics-selected-options-${userId}`;

  const forecastBasisOptions = useMemo(() => {
    if (options?.timePeriodOption === 3) {
      return [
        { label: 'Previous 2 months', value: 2 },
        { label: 'Previous 3 months', value: 3 },
      ];
    }
    if (options?.timePeriodOption === 2) {
      return [
        { label: 'Previous 2 months', value: 2 },
      ];
    }
    return [
      { label: 'Previous 2 months', value: 2 },
      { label: 'Previous 3 months', value: 3 },
      { label: 'Previous 6 months', value: 6 },
    ];
  }, [options?.timePeriodOption]);

  const timePeriodOptions = useMemo(() => computeTimePeriodOptions(contractStartMonth), [contractStartMonth]);

  const { current: forecastPeriodOptions } = useRef([
    {
      label: 'Next 3 months',
      value: 3,
    }, {
      label: 'Next 6 months',
      value: 6,
    }, {
      label: 'Next 12 months',
      value: 12,
    },
  ]);

  const { current: unitDataOptions } = useRef([
    {
      label: 'Don’t convert / Based on meter definition',
      value: 'None',
    },
    {
      label: 'MB',
      value: 'MB',
    },
    {
      label: 'MiB',
      value: 'MiB',
    },
    {
      label: 'GB',
      value: 'GB',
    },
    {
      label: 'GiB',
      value: 'GiB',
    },
    {
      label: 'TB',
      value: 'TB',
    },
    {
      label: 'TiB',
      value: 'TiB',
    }]);

  const { current: viewOptions } = useRef([
    { value: VIEWS.trend, label: 'Trend' },
    { value: VIEWS.singleMonth, label: 'Single Month' },
  ]);

  const monthOptions = [];
  if (options?.view === VIEWS.singleMonth && options?.timePeriodOption) {
    // compute months based on yesterday
    const d = new Date();
    d.setDate(d.getDate() - 1); // yesterday
    d.setDate(1); // First of yesterday's month
    for (let i = 0; i < options?.timePeriodOption ?? 0; i += 1) {
      const label = moment(d).format('MMMM YYYY');
      const value = moment(d).format('MM/YYYY');
      monthOptions.push({ label, value });
      d.setMonth(d.getMonth() - 1);
    }
    const selectedOption = monthOptions.find(o => o.value === options?.month);
    if (selectedOption === undefined) {
      setOptions({ ...options, month: monthOptions[0].value });
    }
  }
  const {
    isFetching: isLoadingLocations,
    error: locationsError,
    data: locations,
  } = useQueryCapacityOptions(true, selectedCustomer);
  const {
    isFetching: isLoadingGroups,
    error: groupErros,
    data: groupOptions,
  } = useQueryCapacityGroups(
    !isLoadingLocations && locationsError === null,
    selectedCustomer,
    options?.locationType ?? null,
    options?.serviceType ?? null,
    options?.groupBy ?? null,
    options?.timePeriodOption ?? null,
    options?.outputUnit ?? null,
  );

  // initialize options when locations (accountId) changes
  useEffect(() => {
    if (locations) {
      const initOptions = options ? { ...options } : { ...getDefaultOptions(), ...JSON.parse(localStorage.getItem(optionsStorageKey)) };
      const foundLocation = locations.filter(location => location.location.id === initOptions.locationType || (initOptions.locationType === 'ALL' && location.location.id == null))[0];
      if (!foundLocation) {
        initOptions.locationType = 'ALL';
      }
      const selectedTimePeriod = timePeriodOptions.find(o => o.value === initOptions.timePeriodOption);
      if (selectedTimePeriod === undefined) {
        let pickFromOptions = timePeriodOptions.filter(o => o.value <= initOptions.timePeriodOption ?? 6);
        if (pickFromOptions.length === 0) {
          pickFromOptions = timePeriodOptions;
        }
        initOptions.timePeriodOption = pickFromOptions[pickFromOptions.length - 1].value;
      }
      setOptions(initOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, optionsStorageKey]);

  const selectedLocation = useMemo(() => {
    if (locations && options?.locationType) {
      return locations.filter(location => location.location.id === options.locationType || (options.locationType === 'ALL' && location.location.id == null))[0];
    }
    return null;
  }, [locations, options?.locationType]);
  const newServiceData = useMemo(() => selectedLocation?.serviceGroups ?? null, [selectedLocation]);
  useEffect(() => {
    if (Array.isArray(newServiceData) && newServiceData.length) {
      const newServiceOptions = [];
      for (let i = 0; i < newServiceData.length; i += 1) {
        const serviceTypeEnum = ServiceTypeStore.getService(newServiceData[i].serviceType);
        const option = {
          label: serviceTypeEnum ? serviceTypeEnum.label : Case.capital(newServiceData[i].serviceType),
          description: serviceTypeEnum ? serviceTypeEnum.label : newServiceData[i].serviceType,
          value: newServiceData[i].serviceType,
        };
        newServiceOptions.push(option);
      }

      const modifiedOptions = { ...options };
      const initialService = selectedService || modifiedOptions?.serviceType;
      let foundService;
      if (initialService) {
        [foundService] = newServiceData.filter(s => (s.serviceType === initialService));
      }
      if (!foundService) {
        [foundService] = newServiceData;
      }
      if (foundService) {
        modifiedOptions.serviceType = foundService.serviceType;
        const foundGroupBy = foundService.groupByOptions.filter(g => g.fieldName === modifiedOptions.groupBy)[0];
        if (!foundGroupBy) {
          modifiedOptions.groupBy = foundService.groupByOptions[0].fieldName;
        }
      }

      // sort service options:
      newServiceOptions.sort((a, b) => {
        if (a.description === 'ALL') {
          return -1;
        }
        if (b.description === 'ALL') {
          return 1;
        }
        const aTitle = a.description.toLowerCase();
        const bTitle = b.description.toLowerCase();
        return (aTitle <= bTitle ? -1 : 1);
      });

      // when we have figured out the services this customer has:
      setServiceOptions(newServiceOptions);
      setSelectedService(undefined);
      setOptions(modifiedOptions);
      setActiveGroups([0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newServiceData]);

  useEffect(() => {
    if (selectedCustomerProp !== selectedCustomer) {
      setSelectedCustomer(selectedCustomerProp);
      setSelectedService(selectedServiceProp);
      setOptions(getDefaultOptions());
      setActiveGroups([0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomerProp]);
  const _getGroupByOptions = (selectedServiceType) => {
    const tempGroupByOptions = [];
    if (selectedServiceType !== undefined && newServiceData != null) {
      const service = newServiceData.filter(s => (s.serviceType === selectedServiceType))[0];
      if (service) {
        service.groupByOptions.forEach((option) => {
          tempGroupByOptions.push({
            label: option.displayName,
            value: option.fieldName,
          });
        });
      }
    }
    return tempGroupByOptions;
  };
  const _onOptionsChange = (event) => {
    const modifiedOptions = { ...options };
    modifiedOptions[event.target.name] = event.value;
    if (['serviceType'].indexOf(event.target.name) >= 0) {
      const selectedServiceType = event.value;
      modifiedOptions.groupBy = _getGroupByOptions(selectedServiceType)[0].value;
    } else if (['timePeriodOption'].indexOf(event.target.name) >= 0) {
      if (options?.priorMonths > modifiedOptions.timePeriodOption) {
        modifiedOptions.priorMonths = modifiedOptions.timePeriodOption;
      }
    } else if (['view'].indexOf(event.target.name) >= 0) {
      if (modifiedOptions.view === VIEWS.singleMonth) {
        modifiedOptions.forecastPeriod = 0;
        modifiedOptions.priorMonths = 0;
      } else {
        modifiedOptions.forecastPeriod = 3;
        modifiedOptions.priorMonths = 2;
      }
    }
    setOptions(modifiedOptions); // calls getData --> setGroupOptions
    setActiveGroups([0]);
  };
  const locationOptions = useMemo(() => {
    const locationDropDown = [{
      value: 'ALL',
      label: 'All',
    }];
    if (locations) {
      locations.filter(({ location }) => location.name != null)
        .forEach(({ location }) => {
          locationDropDown.push({ value: location.id, label: location.name });
        });
    }
    locationDropDown.sort((a, b) => {
      if (a.label === 'All') {
        return -1;
      }
      if (b.label === 'All') {
        return 1;
      }
      return a.label.localeCompare(b.label);
    });
    return locationDropDown;
  }, [locations]);

  // Update local storage when selected options change
  useEffect(() => {
    if (options) {
      localStorage.setItem(optionsStorageKey, JSON.stringify(options));
    }
  }, [options, optionsStorageKey]);

  const tempService = newServiceData ? newServiceData.filter(service => (service.serviceType === options?.serviceType))[0] : undefined;
  const tempGroupBy = tempService ? tempService.groupByOptions.filter(groupBy => (groupBy.fieldName === options?.groupBy))[0] : undefined;
  const tempFieldDisplayName = tempGroupBy ? tempGroupBy.displayName : options?.groupBy;
  const {
    refetch: download,
  } = useQueryCapacityDownload(
    false,
    selectedCustomer,
    options?.serviceType,
    options?.groupBy,
    options?.timePeriodOption,
    tempFieldDisplayName,
    options?.priorMonths,
    options?.forecastPeriod,
    options?.outputUnit
  );
  function _noRowsElement(total) {
    if (isLoadingLocations || isLoadingGroups) {
      return (
        <Box direction='row' align='center' gap='small' justify='center' margin='xlarge'>
          <Loader text='Loading Capacity Planning Results. Please wait ...' />
        </Box>
      );
    }
    if (locationsError != null) {
      return (
        <Box
          direction='row'
          align='center'
          gap='small'
          justify='center'
        >
          <Text color='red'>No Capacity Planning group data</Text>
        </Box>
      );
    }
    if (total === 0) {
      return (
        <Box
          direction='row'
          align='center'
          gap='small'
          justify='center'
          margin='xlarge'
        >
          <Text>No Capacity Planning Results.</Text>
        </Box>
      );
    }
    return '';
  }

  const _renderGroupItems = () => {
    const selectedServiceType = options?.serviceType;
    const service = newServiceData ? newServiceData.filter(s => (s.serviceType === selectedServiceType))[0] : undefined;
    const groupBy = service ? service.groupByOptions.filter(g => (g.fieldName === options?.groupBy))[0] : undefined;
    const categories = {};
    if (groupOptions) {
      groupOptions.forEach((g) => {
        if (categories[g.groupName + g.unit]) {
          categories[g.groupName + g.unit] += 1;
        } else {
          categories[g.groupName + g.unit] = 1;
        }
      });
    }
    return (groupOptions || []).map((group, j) => {
      const isActive = activeGroups.indexOf(j) > -1;
      return (
        <AccordionPanel
          label={(
            <CapacityPlanningGroupLabel
              name={group.groupName}
              band={group.band}
              unit={group.unit}
              serviceCategory={group.serviceCategory}
              outputUnit={group.outputUnit}
              used={group.used}
              usable={group.usable}
              requested={group.requested}
            />
          )}
          key={`${group.groupValue}-${group.unit}`}
        >
          <CapacityPlanningGroup
            index={j}
            isActive={isActive}
            accountId={selectedCustomerProp}
            locationId={options?.locationType}
            groupBy={groupBy}
            priorMonths={options?.priorMonths}
            forecastPeriod={options?.forecastPeriod}
            timePeriodOption={options?.timePeriodOption}
            view={options?.view}
            viewMonth={options?.month}
            group={group}
            outputUnit={options?.outputUnit}
            serviceCategory={group.serviceCategory}
          />
        </AccordionPanel>
      );
    });
  };
  // render portion:
  const serviceFilteredGroupByOptions = _getGroupByOptions(options?.serviceType);
  const renderedGroupItems = _renderGroupItems();
  const renderToast = () => {
    if (locationsError) {
      return (
        <Notification
          status='critical'
          title='Fetch Locations Error'
          message={locationsError.response?.data?.message}
          toast={true}
        />
      );
    }
    if (groupErros) {
      return (
        <Notification
          status='critical'
          title='Fetch Groups Error'
          message={groupErros.response?.data?.message}
          toast={true}
        />
      );
    }
    return undefined;
  };

  if (!selectedCustomer) {
    return <Heading level='2'>Please select a customer</Heading>;
  }
  return (
    <Box flex={false} overflow='auto' height='full'>
      <Box direction='row' pad={{ vertical: 'small' }} flex={false} justify='between'>
        <Box flex={true} wrap={true} direction='row'>
          <Box pad={{ horizontal: 'small', bottom: 'small' }} flex={false}>
            <FormField label='Location'>
              <Select
                name='locationType'
                id={IDUtil.getId('locationTypeDropDown')}
                options={locationOptions || []}
                value={options?.locationType}
                onChange={_onOptionsChange}
                labelKey='label'
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
          </Box>
          <Box pad={{ horizontal: 'small', bottom: 'small' }} flex={false}>
            <FormField label='Service'>
              <Select
                name='serviceType'
                id={IDUtil.getId('serviceTypeDropDown')}
                options={serviceOptions}
                value={options?.serviceType}
                onChange={_onOptionsChange}
                labelKey='label'
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
          </Box>
          <Box pad={{ horizontal: 'small', bottom: 'small' }} flex={false}>
            <FormField label='Group by'>
              <Select
                name='groupBy'
                id={IDUtil.getId('groupByDropDown')}
                options={serviceFilteredGroupByOptions}
                value={options?.groupBy}
                onChange={_onOptionsChange}
                labelKey='label'
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
          </Box>
          <Box pad={{ horizontal: 'small', bottom: 'small' }} flex={false}>
            <FormField label='Display Unit'>
              <Select
                name='outputUnit'
                id={IDUtil.getId('outputUnitDropDown')}
                options={unitDataOptions}
                value={options?.outputUnit}
                onChange={_onOptionsChange}
                labelKey='label'
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
          </Box>
          <Box pad={{ horizontal: 'small', bottom: 'small' }} flex={false}>
            <FormField label='View'>
              <Select
                name='view'
                id={IDUtil.getId('viewDropDown')}
                options={viewOptions}
                value={options?.view}
                onChange={_onOptionsChange}
                labelKey='label'
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
          </Box>
          <Box pad={{ horizontal: 'small', bottom: 'small' }} flex={false}>
            <FormField label='Time Period'>
              <Select
                name='timePeriodOption'
                id={IDUtil.getId('timePeriodDropDown')}
                options={timePeriodOptions}
                value={options?.timePeriodOption}
                onChange={_onOptionsChange}
                labelKey='label'
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
          </Box>
          {options?.view === VIEWS.singleMonth && (
            <Box pad={{ horizontal: 'small', bottom: 'small' }} flex={false}>
              <FormField label='Month'>
                <Select
                  name='month'
                  id={IDUtil.getId('monthDropDown')}
                  options={monthOptions}
                  value={options?.month}
                  labelKey='label'
                  valueKey={{ key: 'value', reduce: true }}
                  onChange={_onOptionsChange}
                />
              </FormField>
            </Box>
          )}
          {options?.view === VIEWS.trend && (
            <>
              <Box pad={{ horizontal: 'small', bottom: 'small' }} flex={false}>
                <FormField label='Base forecast on'>
                  <Select
                    name='priorMonths'
                    id={IDUtil.getId('priorMonthsDropDown')}
                    options={forecastBasisOptions}
                    value={options?.priorMonths}
                    onChange={_onOptionsChange}
                    labelKey='label'
                    valueKey={{ key: 'value', reduce: true }}
                  />
                </FormField>
              </Box>
              <Box pad={{ horizontal: 'small', bottom: 'small' }} flex={false}>
                <FormField label='Forecast period'>
                  <Select
                    name='forecastPeriod'
                    id={IDUtil.getId('forecastPeriodDropDown')}
                    options={forecastPeriodOptions}
                    value={options?.forecastPeriod}
                    onChange={_onOptionsChange}
                    labelKey='label'
                    valueKey={{ key: 'value', reduce: true }}
                  />
                </FormField>
              </Box>
            </>
          )}
        </Box>
        <Box pad={{ horizontal: 'small', top: 'medium' }} justify='start' flex={false}>
          <Button secondary={true} id='ExportCSVBtn' label='Export to CSV' onClick={() => download()} />
        </Box>
      </Box>
      {_noRowsElement(renderedGroupItems ? renderedGroupItems.length : 0)}

      {!isLoadingLocations && !isLoadingGroups
        && (
          <Box flex={false}>
            <Accordion
              activeIndex={activeGroups}
              multiple={true}
              animate={false}
              onActive={(event) => {
                setActiveGroups(event);
              }}
            >
              {renderedGroupItems}
            </Accordion>
          </Box>
        )}
      {renderToast()}
    </Box>
  );
};

CapacityPlanning.propTypes = {
  selectedCustomer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    contractStartMonth: PropTypes.string,
  }).isRequired,
  selectedService: PropTypes.string,
};

export default CapacityPlanning;
