// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Header, Heading, Text, Toolbar
} from 'grommet';
import { FormPreviousLink } from 'grommet-icons';
import { useNavigate } from 'react-router-dom';

const GLBMHeading = ({
  back = undefined,
  title = undefined,
  subtitle = undefined,
  search = undefined,
  actions = undefined,
  children = undefined,
  height = undefined,
  level = '2',
  inline = false,
  ...rest
}) => {
  const navigate = useNavigate();

  return (
    <Box
      pad={{
        left: 'small', top: 'small', right: 'small', bottom: 'xsmall'
      }}
      flex={false}
      height={height}
      justify='center'
      {...rest}
    >
      <Header justify='start' gap='none' align={inline ? 'center' : 'start'} direction={inline ? 'row' : 'column'}>
        <Box
          direction='row'
          align='center'
          as='header'
          flex={false}
          gap='small'
          justify='between'
          pad={{ horizontal: 'xxsmall' }}
        >
          {back && (
            <Button
              kind='toolbar'
              onClick={typeof back === 'string' ? () => navigate(back) : back}
              icon={<FormPreviousLink size='xxlarge' color='brand' />}
              hoverIndicator={true}
              plain={true}
            />
          )}
          {subtitle && title ? (
            <Box margin={{ bottom: 'small' }}>
              <Heading level={level} weight='bold' fill={true} margin={{ bottom: 'xsmall' }}>{title}</Heading>
              <Text>{subtitle}</Text>
            </Box>
          ) : (title && <Heading level={level || '2'} weight='bold' fill={true}>{title}</Heading>)}
        </Box>
        <Box pad='xxsmall' direction='row' justify='between' align='end' fill='horizontal' flex={true}>
          <Toolbar>
            {search}
          </Toolbar>
          <Toolbar>
            {actions}
            {children}
          </Toolbar>
        </Box>
      </Header>
    </Box>
  );
};

GLBMHeading.propTypes = {
  back: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  search: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  actions: PropTypes.arrayOf(PropTypes.node),
  children: PropTypes.arrayOf(PropTypes.node),
  height: PropTypes.string,
  level: PropTypes.string,
  inline: PropTypes.bool,
};

export default GLBMHeading;
