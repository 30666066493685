// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormField,
  Header, Heading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  TextInput,
} from 'grommet';
import { Add, FormTrash } from 'grommet-icons';
import CurrencyUtils from '../../../../../i18n/CurrencyUtil';
import { UserType } from '../../../../../shared/constants/UserType';
import IDUtil from '../../../../../shared/util/IDUtil';
import { MarkupType } from '../../../../../constants/MarkupType';
import {
  calculateDownstreamRate,
  calculateMarkup,
  isLessThanFiveDecimalPlaces,
  isLessThanNineDecimalPlaces,
  isNumber,
} from '../util';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';

const TableData = ({ style, children }) => {
  return (
    <TableCell
      className='rateTableRow'
      style={{
        verticalAlign: 'baseline',
        alignItems: 'baseline',
        maxWidth: 115,
        ...style,
      }}
      align='start'
      flex={true}
      fill={true}
    >
      {children}
    </TableCell>
  );
};

const RatesStepEditor = (props) => {
  const [{ customer, permissions }] = useServiceEditorContext();
  const [steps, setSteps] = useState(Object.entries(props.steps)
    .sort(([a], [b]) => ((parseFloat(a) < parseFloat(b)) ? -1 : (a === b) ? 0 : 1))
    .reduce((arr, [threshold, {
      rate, name, markup, downstreamRate,
    }]) => (
      [...arr, {
        threshold, rate, name, markup, downstreamRate,
      }]
    ), []));

  useEffect(() => {
    const steps = Object.entries(props.steps)
      .sort(([a], [b]) => ((parseFloat(a) < parseFloat(b)) ? -1 : (a === b) ? 0 : 1))
      .reduce((arr, [threshold, {
        rate, name, markup, downstreamRate,
      }]) => (
        [...arr, {
          threshold, rate, name, markup, downstreamRate,
        }]
      ), []);
    setSteps(steps);
  }, [props.markupType]);

  const _updateMaster = (steps) => {
    const newSteps = JSON.parse(JSON.stringify(props.steps));
    Object.keys(newSteps).forEach((key) => {
      delete newSteps[key];
    });
    Object.assign(newSteps, steps.reduce((obj, {
      threshold, rate, name, markup, downstreamRate,
    }) => {
      obj[threshold] = {
        rate: rate !== undefined ? parseFloat(rate) : undefined,
        name,
        markup: markup !== undefined ? parseFloat(markup) : undefined,
        downstreamRate: downstreamRate !== undefined ? parseFloat(downstreamRate) : undefined,
      };
      return obj;
    }, {}));
    props.setSteps(newSteps);
  };

  const _onChange = ({ target: { value, name } } = { target: { name: null } }, index) => {
    const newSteps = JSON.parse(JSON.stringify(steps));

    if (name) {
      switch (name) {
        case 'name':
          newSteps[index][name] = value;
          break;
        case 'markup':
        case 'downstreamRate':
          // if user presses minus and not at the beginning of the string
          const minusCount = (value.match(/-/g) || []).length;
          if (minusCount === 1) {
            const minusIndex = value.indexOf('-');
            if (minusIndex !== -1 && minusIndex > 0) {
              value = `-${value.split('-').join('')}`;
            }
          } else if (minusCount === 2) {
            // if user presses minus a second time:
            value = value.split('-').join('');
          }

          if (value === '-') {
            newSteps[index][name] = value;
          } else if (value === '') {
            newSteps[index][name] = undefined;
          } else if (isNumber(value)) {
            if (name === 'markup' && isLessThanFiveDecimalPlaces(value)) {
              newSteps[index][name] = value;
            } else if (name === 'downstreamRate' && isLessThanNineDecimalPlaces(value)) {
              newSteps[index][name] = value;
            }
          }

          // calculate and set the 'other' value:
          if (name === 'markup') {
            newSteps[index].downstreamRate = calculateDownstreamRate(newSteps[index].markup, newSteps[index].rate);
          } else if (name === 'downstreamRate') {
            newSteps[index].markup = calculateMarkup(newSteps[index].downstreamRate, newSteps[index].rate);
          }

          break;
        default:
          if (!value || value === '.' || (isNumber(value) && isLessThanNineDecimalPlaces(value))) {
            newSteps[index][name] = value;
          }
          break;
      }
    }
    _updateMaster(newSteps);
    setSteps(newSteps);
  };

  const nameBandRenderer = (step, index) => (
    <Box direction='row'>
      <Box flex={true} style={{ whiteSpace: 'nowrap' }}>
        {
            (props.canEdit && (props.userType === UserType.SUPER.enumKey || props.userType === UserType.SERVICE_DEV.enumKey)) ? (
              <FormField required={true} error={!step.name || step.name === undefined ? 'Required' : undefined}>
                <TextInput
                  value={step.name}
                  name='name'
                  onChange={(event) => {
                    _onChange(event, index);
                  }}
                  id={IDUtil.getId('MeterStepEditorNameInput', index)}
                />
              </FormField>
            ) : (<Box pad={{ top: 'small' }}>{step.name}</Box>)
          }
      </Box>
    </Box>
  );

  const _onBlur = ({ target: { value, name } } = { target: { name: null } }, index) => {
    const newSteps = JSON.parse(JSON.stringify(steps));
    if (newSteps && newSteps.length) {
      const step = newSteps[index];
      const value = step[name];
      if (!isNumber(value)) {
        switch (name) {
          case 'rate':
            step[name] = 0;
            break;
          default:
            step[name] = undefined;
        }
      }
    }

    _updateMaster(newSteps);
    setSteps(newSteps);
  };

  const _getMarkup = (step, index) => {
    const { markup } = step;
    const { markupType } = props;
    if (markupType === MarkupType.MARKUP_PERCENT) {
      return (
        <Box direction='row'>
          <Box flex={true}>
            <TextInput
              value={markup === undefined ? '' : markup}
              name='markup'
              style={{ background: 'white' }}
              onChange={(event) => {
                _onChange(event, index);
              }}
              onBlur={event => _onBlur(event, index)}
              id={IDUtil.getId('MeterStepEditorThresholdInput', index)}
            />
          </Box>
          <Box margin={{ left: 'small' }}>%</Box>
        </Box>
      );
    }

    if (markupType === MarkupType.DIRECT_RATE) {
      if (markup !== undefined) {
        return (
          <Box direction='row'>
            <Box
              margin={{ left: 'small' }}
              flex={true}
            >
              {(!markup || markup === Infinity) ? '-' : `${CurrencyUtils.getNumberString(parseFloat(markup), 4)}%`}
            </Box>
          </Box>
        );
      }
    }
    return (
      <Box direction='row'>
        <Box margin={{ left: 'small' }} flex={true}>
          -
        </Box>
      </Box>
    );
  };

  const _getCustomerRate = ({ markup, downstreamRate }, index) => {
    const { markupType } = props;
    const { contractCurrency } = customer;

    if (markupType === MarkupType.DIRECT_RATE) {
      return (
        <Box direction='row'>
          <Box flex={true}>
            <TextInput
              value={downstreamRate === undefined ? '' : downstreamRate}
              name='downstreamRate'
              style={{ background: 'white' }}
              onChange={(event) => {
                _onChange(event, index);
              }}
              onBlur={event => _onBlur(event, index)}
              id={IDUtil.getId('MeterStepEditorThresholdInput', index)}
            />
          </Box>
        </Box>
      );
    }

    if (markupType === MarkupType.MARKUP_PERCENT) {
      if (downstreamRate !== undefined) {
        return (
          <Box direction='row'>
            <Box
              margin={{ left: 'small' }}
              flex={true}
            >
              {`${CurrencyUtils.getNumberString(parseFloat(downstreamRate), 8, null, contractCurrency)}`}
            </Box>
          </Box>
        );
      }
    }

    return 'Not Available';
  };

  const _getPartnerRowTd = (step, index) => {
    const backgroundColor = { backgroundColor: 'rgba(248, 248, 248, 0.95)' };
    return permissions.canMarkupRates ? [
      <TableData style={backgroundColor}>{_getMarkup(step, index)}</TableData>,
      <TableData style={backgroundColor}>{_getCustomerRate(step, index)}</TableData>,
    ] : [];
  };

  const startBandRenderer = (step, index) => {
    const { threshold } = step;
    const textBand = `>= ${threshold}`;
    const inputBand = (
      <Box direction='row' style={{ alignItems: 'baseline' }}>
        {index === 0
          ? (
            <Box
              flex={true}
              fill={true}
              pad={{ top: 'small' }}
            >
              {textBand}
            </Box>
          ) : (
            <Box flex={true} direction='row' style={{ alignItems: 'baseline' }}>
              <Box margin={{ right: 'small' }}>{'>'}</Box>
              {
                props.canEdit ? (
                  <FormField required={true} error={!threshold || threshold === undefined ? 'Required' : undefined}>
                    <TextInput
                      value={threshold}
                      name='threshold'
                      onChange={(event) => {
                        _onChange(event, index);
                      }}
                      id={IDUtil.getId('MeterStepEditorThresholdInput', index)}
                    />
                  </FormField>
                ) : threshold
              }
            </Box>
          )}
      </Box>
    );
    return inputBand;
  };

  const endBandRenderer = (step, index, steps) => {
    const { threshold } = steps[index + 1] || {};
    return (
      <Box
        direction='column'
        style={{ alignItems: 'baseline' }}
        fill={true}
        pad={{ top: 'small' }}
      >
        <Box margin={{ right: 'small' }} fill={true}>{threshold ? `and <= ${threshold}` : 'and up'}</Box>
      </Box>
    );
  };

  const rateRenderer = ({ rate }, index) => {
    const { contractCurrency } = customer;
    return (
      <Box flex={true}>
        {
          props.canEdit ? (
            <FormField required={true} error={rate === undefined ? 'Required' : undefined}>
              <TextInput
                value={rate === undefined ? '' : rate}
                name='rate'
                onChange={(event) => {
                  _onChange(event, index);
                }}
                onBlur={event => _onBlur(event, index)}
                id={IDUtil.getId('MeterStepEditorRateInput', index)}
              />
            </FormField>
          ) : (rate !== undefined ? `${CurrencyUtils.getNumberString(rate, 8, null, contractCurrency)}` : 'Not Available')
        }
      </Box>
    );
  };

  const _onStepAdd = () => {
    const newSteps = JSON.parse(JSON.stringify(steps));
    if (steps.length) {
      newSteps.push({
        name: `Band ${newSteps.length + 1}`,
        threshold: parseFloat(newSteps[newSteps.length - 1].threshold || 0) + 1,
        rate: newSteps[newSteps.length - 1].rate,
      });
    } else {
      newSteps.push({ name: 'Band 1', threshold: 0, rate: 0 });
    }

    _updateMaster(newSteps);
    setSteps(newSteps);
  };

  const _onStepRemove = (index) => {
    const newSteps = JSON.parse(JSON.stringify(steps));
    newSteps.splice(index, 1);
    if (newSteps.length) {
      newSteps[0].threshold = 0;
    }
    if (newSteps.length > 0) {
      _updateMaster(newSteps);
      setSteps(newSteps);
    } else {
      props.showNoBandsError();
    }
  };

  const removeBand = index => (props.canEdit ? (
    <Box pad={{ top: 'xsmall' }}>
      <Button
        icon={<FormTrash />}
        key={index}
        onClick={() => _onStepRemove(index)}
        id={IDUtil.getId('MeterStepEditorDeleteButton', index)}
        a11yTitle='Delete Step'
      />
    </Box>
  ) : null);

  const _getCurrencySymbol = (contractCurrency, locale) => {
    if (contractCurrency) {
      return CurrencyUtils.getCurrencyString(undefined, undefined, locale, contractCurrency).replace(/[0,.]+/gi, '').trim();
    }
    return '$';
  };

  const _getTableHeaders = () => {
    const TableHeading = ({ children }) => (
      <TableCell scope='col' border='bottom'>
        {children}
      </TableCell>
    );
    const { canMarkupRates } = permissions;
    const { contractCurrency, locale } = customer;
    const partnerHeaders = canMarkupRates ? ['Markup', `Customer Rate (${_getCurrencySymbol(contractCurrency, locale)})`] : [];
    return ['Name', 'Start', 'End', `Rate Per Unit (${_getCurrencySymbol(contractCurrency, locale)})`, ...partnerHeaders, ''].map(label => <TableHeading>{label}</TableHeading>);
  };

  const _getRows = () => steps.map((step, index, steps) => (
    <TableRow key={index} style={{ verticalAlign: 'top' }}>
      <TableData>{nameBandRenderer(step, index)}</TableData>
      <TableData>{startBandRenderer(step, index)}</TableData>
      <TableData>{endBandRenderer(step, index, steps)}</TableData>
      <TableData>{rateRenderer(step, index)}</TableData>
      {_getPartnerRowTd(step, index)}
      <TableData style={{ maxWidth: 50 }}>{removeBand(index)}</TableData>
    </TableRow>
  ));
  return (
    <Box flex='grow' className='rates-steps-editor tiny-inputs'>
      <Header size='small' justify='between'>
        <Heading level='3'>Steps and Rates</Heading>
        {props.canEdit
          ? (
            <Button
              icon={<Add />}
              onClick={props.canEdit ? _onStepAdd : undefined}
              id={IDUtil.getId('MeterStepEditorAddButton')}
              a11yTitle='Add Step'
            />
          ) : undefined}
      </Header>
      <Table responsive={false} scrollable={false} data-e2e='steps-and-rates'>
        <TableHeader pad='small'>
          <TableRow>
            {_getTableHeaders()}
          </TableRow>
        </TableHeader>
        <TableBody>
          {_getRows()}
        </TableBody>
      </Table>
    </Box>
  );
};

export default RatesStepEditor;
