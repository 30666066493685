// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, FormField, Text, TextInput,
} from 'grommet';
import ReadOnlyField from '../../../../../shared/component/ReadOnlyField';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';
import { _isNumber } from './utils';

const WindowsBillingMethodField = (props) => {
  const [serviceEditor, setServiceEditor] = useServiceEditorContext();

  const _onChangeUtilization = (value) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));

    if (value && value.length >= 2 && value.startsWith('0')) {
      value = value.slice(1);
    }

    if (_isNumber(value)) {
      if (parseFloat(value) > 100) {
        newState.options.config.billingMethodUtilization = 100;
      } else if (parseFloat(value) < 0) {
        newState.options.config.billingMethodUtilization = 0;
      } else {
        newState.options.config.billingMethodUtilization = value;
      }
    } else if (value === '') {
      newState.options.config.billingMethodUtilization = 0;
    }
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
  };

  const _getEditableLayout = () => (
    <FormField label={props.labelUtilization ?? 'Per core utilization'} help={props.noteUtilization}>
      <Box flex='grow' className='tiny-inputs' direction='row' gap='small' align='center' pad={{ vertical: 'small' }}>
        <Box style={{ minWidth: '12px' }} />
        <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}>
          <TextInput
            value={serviceEditor.options.config.billingMethodUtilization}
            name='perCoreUtilization'
            onChange={(event) => {
              _onChangeUtilization(event.target.value);
            }}
            id='perCoreUtilization'
            plain={true}
          />
        </div>
        <Text weight={100} size='small'>Utilization %</Text>
      </Box>
    </FormField>
  );

  const _getReadOnlyLayout = () => {
    const labelFromBillingMethodMap = new Map()
      .set('PER_CORE_UTILIZATION', 'Per core utilization');

    const { windowsUtilBillingMethod } = serviceEditor.options.config;

    return (
      <ReadOnlyField label='Per core utilization' value={labelFromBillingMethodMap.get(windowsUtilBillingMethod)} />
    );
  };

  return props.readOnly ? _getReadOnlyLayout() : _getEditableLayout();
};

WindowsBillingMethodField.propTypes = {
  noteUtilization: PropTypes.string,
  labelUtilization: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  setDirty: PropTypes.func.isRequired,
};

export default WindowsBillingMethodField;
