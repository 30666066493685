// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import find from 'lodash/find';
import get from 'lodash/get';
import {
  Box,
  Button,
  CheckBox,
  Footer,
  FormField,
  Text, TextInput,
} from 'grommet';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import DateTime from '../../../../shared/component/DateTime';
import GLBMLayer from '../../../../shared/component/GLBMLayer';
import IDUtil from '../../../../shared/util/IDUtil';
import { insertIf } from '../../../../shared/util/BasicUtil';
import ConfigureResourcesContext from './ConfigureResourcesContext';
import RaidTypeEdit from './types/RaidTypeEdit';
import InstalledCapacityEdit from './types/InstalledCapacityEdit';
import ReplicationRatioEdit from './types/ReplicationRatioEdit';
import ResourceRevisionEdit from './components/resource-revision/ResourceRevisionEdit';
import {
  adjustEffectiveDate,
  getDuplicateRevisions,
  sortEffectiveDate,
} from './components/resource-revision/Util';

const EquipmentBulkEdit = (props) => {
  const { distinctColumns } = props.serviceType.configureResources;
  const hasRaidType = useMemo(() => find(distinctColumns, obj => get(obj, 'column.field') === 'details.raidType'), []);
  const hasInstalledCapacity = useMemo(() => find(distinctColumns, obj => get(obj, 'column.field') === 'details.installedCapacity'), []);
  const hasReplicationRatio = useMemo(() => find(distinctColumns, obj => get(obj, 'column.field') === 'details.replicationRatio'), []);

  const [equipment, setEquipment] = useState(props.equipment);
  const [values, setValues] = useState({
    include: (props.equipment.length === 1) ? props.equipment[0].data.include : props.equipment.every(e => e.data.include),
    locationRevisions: (props.equipment.length === 1) ? adjustEffectiveDate(props.equipment[0].data.locationRevisions, 'YYYY-MM') : null,
    mappedTierRevisions: (props.equipment.length === 1) ? adjustEffectiveDate(props.equipment[0].data.mappedTierRevisions, 'YYYY-MM') : null,
    decommissionDate: (props.equipment.length === 1) ? props.equipment[0].data.decommissionDate : null,
    decommissionNotes: (props.equipment.length === 1) ? props.equipment[0].data.decommissionNotes : '',
    ...insertIf(hasRaidType, { raidType: (props.equipment.length === 1) ? props.equipment[0].data.details.raidType : null }),
    ...insertIf(hasInstalledCapacity, { installedCapacity: (props.equipment.length === 1) ? props.equipment[0].data.details.installedCapacity : null }),
    ...insertIf(hasReplicationRatio, { replicationRatio: (props.equipment.length === 1) ? props.equipment[0].data.details.replicationRatio : null }),
  });

  const [update, setUpdate] = useState({
    include: false,
    locationRevisions: false,
    mappedTierRevisions: false,
    decommissionDate: false,
    decommissionNotes: false,
    ...insertIf(hasRaidType, { raidType: false }),
    ...insertIf(hasInstalledCapacity, { installedCapacity: false }),
    ...insertIf(hasReplicationRatio, { replicationRatio: false }),
  });
  const [errors, setErrors] = useState({});
  const [isModified, setIsModified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const _isEOSTPossible = useMemo(
    () => (((equipment.filter(x => !x.data.include).length) > 0 && !(update.include && values.include)) || (update.include && !values.include)),
    [equipment, update, values]
  );

  const _getEOSTErrors = useMemo(() => {
    // Bulk Exclude with EOST
    if (equipment?.length && update.include && !values.include) {
      for (let i = 0; i < equipment.length; i += 1) {
        if (equipment[i].data.decommissionDate) {
          return 'One or more equipment has an End of System Term Date and cannot be excluded.';
        }
      }
    }

    const date = moment(values.decommissionDate, 'YYYY-MM-DD', true);
    const isValid = date.isValid();

    return (
      (_isEOSTPossible) && !!values.decommissionDate)
      ? 'Only included equipment may have an End of System Term Date.'
      : (update.decommissionDate && values.decommissionDate && (!values.decommissionDate.match(/(\d{4})-(\d{2})-(\d{2})/) || !isValid) && !!values.decommissionDate)
        ? 'Please enter a properly formatted date: YYYY-MM-DD' : null;
  }, [equipment, update, values, _isEOSTPossible]);

  const _onChange = (attribute, value) => {
    const newValues = cloneDeep(values);
    const newUpdates = { ...update };
    newValues[attribute] = value;
    newUpdates[attribute] = true;
    setValues(newValues);
    setUpdate(newUpdates);
    setIsModified(true);
  };

  const _onToggleInclude = (event) => {
    const newValues = cloneDeep(values);
    const newUpdates = { ...update };
    newValues.include = event.target.checked;
    newUpdates[event.target.name] = true;
    setValues(newValues);
    setUpdate(newUpdates);
    setIsModified(true);
  };

  const _onBulkChecked = (event) => {
    const newUpdates = { ...update };
    newUpdates[event.target.name] = event.target.checked;
    setUpdate(newUpdates);
    setIsModified(true);
  };

  const renderLocation = () => {
    let result;
    if (props.options.locationRevisions !== 'HIDDEN' && props.options.locationRevisions === 'EDITABLE') {
      result = (
        <ResourceRevisionEdit
          name='Location'
          attribute='locationRevisions'
          readOnly={props.readOnly}
          setDirty={props.setDirty}
          isBulk={equipment.length > 1}
          mappedOptions={props.locations}
          revisions={cloneDeep(values.locationRevisions)}
          update={update.locationRevisions}
          onChange={_onChange}
          onBulkChange={_onBulkChecked}
          contractStartMonth={props.contractStartMonth}
          isSubmitted={isSubmitted}
        />
      );
    }
    return result;
  };

  const renderInclude = () => (
    <Box direction='row' align='center' flex={false}>
      {equipment.length > 1
          && (
            <Box margin={{ top: 'medium' }}>
              <CheckBox
                name='include'
                checked={update.include}
                onChange={e => _onBulkChecked(e)}
                id={IDUtil.getId('UpdateIncludeCheckBox')}
                data-testid='equipment-bulk-edit-include-checkbox'
              />
            </Box>
          )}
      <FormField
        key='serviceMappingInclude'
        label='Include'
        error={errors.include}
      >
        <CheckBox
          checked={values.include}
          name='include'
          toggle={true}
          id={IDUtil.getId('UpdateIncludeSwitch')}
          onChange={e => _onToggleInclude(e)}
        />
      </FormField>
    </Box>
  );

  const renderTier = () => {
    let result;
    const isReadOnlyTierEquipment = props.serviceType.serviceOptions.readOnlyEquipmentTierMapping;
    if (props.options.mappedTierRevisions !== 'HIDDEN' && !isReadOnlyTierEquipment && props.options.mappedTierRevisions === 'EDITABLE') {
      result = (
        <ResourceRevisionEdit
          name='Tier'
          attribute='mappedTierRevisions'
          readOnly={props.readOnly}
          setDirty={props.setDirty}
          isBulk={equipment.length > 1}
          mappedOptions={props.config.mappedTiers}
          revisions={cloneDeep(values.mappedTierRevisions)}
          update={update.mappedTierRevisions}
          onChange={_onChange}
          onBulkChange={_onBulkChecked}
          contractStartMonth={props.contractStartMonth}
          isSubmitted={isSubmitted}
        />
      );
    }
    return result;
  };

  const _onEOSTChange = (value) => {
    const newValues = { ...values };
    const newUpdates = { ...update };
    newValues.decommissionDate = value?.length ? value : null;
    newUpdates.decommissionDate = true;
    setValues(newValues);
    setUpdate(newUpdates);
    setIsModified(true);
  };

  const _onNotesChange = (event) => {
    const newValues = { ...values };
    const newUpdates = { ...update };
    const notes = event.target.value;
    newValues.decommissionNotes = typeof notes === 'string' ? notes : '';
    newUpdates.decommissionNotes = true;
    setValues(newValues);
    setUpdate(newUpdates);
    setIsModified(true);
  };

  const hasTierErrors = useMemo(() => (values.mappedTierRevisions && values.mappedTierRevisions.length > 0 ? values.mappedTierRevisions.reduce(
    (error, revision) => (error
        || (!revision.id || !revision.effectiveDate)
        || (revision.effectiveDate && !moment(revision.effectiveDate, 'YYYY-MM', true).isValid())),
    false
  ) : false) || getDuplicateRevisions(values.mappedTierRevisions).length > 0, [values.mappedTierRevisions]);

  const hasLocationErrors = useMemo(() => (values.locationRevisions && values.locationRevisions.length > 0 ? values.locationRevisions.reduce(
    (error, revision) => (error
      || (!revision.id || !revision.effectiveDate)
      || (revision.effectiveDate && !moment(revision.effectiveDate, 'YYYY-MM', true).isValid())),
    false
  ) : false) || getDuplicateRevisions(values.locationRevisions).length > 0, [values.locationRevisions]);

  const _hasErrors = useMemo(() => {
    let hasErrors = false;
    Object.keys(errors).forEach((x) => {
      if (typeof errors[x] === 'string') {
        hasErrors = true;
      }
    });
    return hasErrors || hasTierErrors || hasLocationErrors || typeof _getEOSTErrors === 'string' || (values.decommissionDate && !values.decommissionNotes);
  }, [errors, values, hasTierErrors, hasLocationErrors, _getEOSTErrors]);

  const _onSubmit = () => {
    setIsSubmitted(true);

    if (_hasErrors) {
      return;
    }

    // if single and not modified, leave. If bulk edit, make sure we are planning to update at least one property:
    if ((equipment.length === 1 && !isModified) || (!Object.values(update).reduce((acc, value) => acc || value, false))) {
      props.onClose();
      return;
    }

    Object.keys(update).forEach((key) => {
      if (update[key]) {
        equipment.forEach((e) => {
          switch (key) {
            case 'raidType':
              e.data.setDeep('details.raidType', values[key]);
              break;
            case 'installedCapacity':
              e.data.setDeep('details.installedCapacity', +values[key]);
              break;
            case 'replicationRatio':
              e.data.setDeep('details.replicationRatio', Math.round(values[key]) == values[key] ? Math.round(values[key]) : values[key]);
              break;
            case 'mappedTierRevisions':
            case 'locationRevisions':
              e.data[key] = adjustEffectiveDate(sortEffectiveDate(values[key] || []), 'YYYY-MM-DD');
              break;
            default:
              e.data[key] = values[key];
          }

          // if no EOST date, clear notes:
          if (e.data.decommissionNotes && !e.data.decommissionDate) {
            e.data.decommissionNotes = '';
          }
        });
      }
    });

    props.onChange(equipment);
  };

  const renderTypes = () => (
    <Box flex={false} gap='medium'>
      {hasRaidType && (
        <RaidTypeEdit
          readOnly={props.readOnly}
          setDirty={props.setDirty}
          isBulk={equipment.length > 1}
          config={props.config}
          value={values.raidType}
          update={update.raidType}
          onChange={_onChange}
          onBulkChange={_onBulkChecked}
          column={hasRaidType}
        />
      )}
      {hasInstalledCapacity && (
      <InstalledCapacityEdit
        readOnly={props.readOnly}
        setDirty={props.setDirty}
        isBulk={equipment.length > 1}
        config={props.config}
        value={values.installedCapacity}
        update={update.installedCapacity}
        onChange={_onChange}
        onBulkChange={_onBulkChecked}
        column={hasInstalledCapacity}
      />
      )}
      {hasReplicationRatio && (
      <ReplicationRatioEdit
        readOnly={props.readOnly}
        setDirty={props.setDirty}
        isBulk={equipment.length > 1}
        config={props.config}
        value={values.replicationRatio}
        update={update.replicationRatio}
        onChange={_onChange}
        onBulkChange={_onBulkChecked}
        column={hasReplicationRatio}
      />
      )}
    </Box>
  );

  const renderDecomissionDate = () => (
    <Box direction='row' align='center' flex={false}>
      {equipment.length > 1
          && (
            <Box margin={{ top: 'large' }}>
              <CheckBox
                name='decommissionDate'
                checked={update.decommissionDate}
                onChange={e => _onBulkChecked(e)}
                id={IDUtil.getId('UpdateEndOfSystemTermCheckBox')}
                data-testid='equipment-bulk-edit-decomissionDate-checkbox'
              />
            </Box>
          )}
      <FormField
        key='decommissionDate'
        help='(only used when taking a resource out of service)'
        label='End of System Term Date'
        error={_getEOSTErrors}
      >
        <DateTime
          id={IDUtil.getId('UpdateEndOfSystemTermDate')}
          name='decommissionDate'
          format='YYYY-MM-DD'
          onChange={_onEOSTChange}
          value={values.decommissionDate || ''}
        />
      </FormField>
    </Box>
  );

  const renderNotes = () => {
    if (values.decommissionDate) {
      return (
        <Box direction='row' align='center' flex={false}>
          {equipment.length > 1 && <Box style={{ width: '53px' }} />}
          <FormField
            key='decommissionNotes'
            label='Notes'
            help='limit of 255 characters.'
            error={(values.decommissionDate && !values.decommissionNotes) ? 'Required' : null}
            width='100%'
          >
            <TextInput
              name='decommissionNotes'
              required={values.decommissionDate}
              onChange={e => _onNotesChange(e)}
              value={values.decommissionNotes}
              maxLength={255}
            />
          </FormField>
        </Box>
      );
    }
  };

  return (
    <GLBMLayer
      onClose={props.onClose}
      onEsc={props.onClose}
      onClickOutside={props.onClose}
      position='right'
      full='vertical'
      title={(props.equipment.length > 1) ? 'Bulk Edit Resources' : 'Edit Resource'}
    >
      <Box
        direction='column'
        style={{ 'maxWidth': '575px', 'minWidth': '500px' }}
        fill={true}
      >
        <Box flex={true} pad={{ 'horizontal': 'medium' }} overflow='auto'>
          <Box flex={false}>
            <ConfigureResourcesContext
              serviceType={props.serviceType}
              equipment={equipment}
            />
          </Box>
          <Box flex={false}>
            <Text size='large'>Resource Properties:</Text>
            <Box flex={false} gap='medium'>
              {renderInclude()}
              {renderTier()}
              {renderLocation()}
              {renderTypes()}
              {renderDecomissionDate()}
              {renderNotes()}
            </Box>
          </Box>
        </Box>
        <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
          <Footer flex={false} justify='start' gap='small'>
            <Button
              label='OK'
              primary={true}
              onClick={() => _onSubmit()}
              data-testid='equipment-bulk-edit-submit-button'
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={() => props.onClose()}
              data-testid='equipment-bulk-edit-cancel-button'
            />
          </Footer>
        </Box>
      </Box>
    </GLBMLayer>
  );
};

export default EquipmentBulkEdit;
