// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';

const TypeLabel = ({ label = '' }) => (
  <Box direction='row' align='center' gap='small'>
    <span>{label}</span>
  </Box>
);

TypeLabel.propTypes = {
  label: PropTypes.string,
};

export default TypeLabel;
