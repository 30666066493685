// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  Box, Button, Footer, FormField, TextInput,
} from 'grommet';
import { FormAdd } from 'grommet-icons';
import GLBMLayer from '../../../../../shared/component/GLBMLayer';
import IDUtil from '../../../../../shared/util/IDUtil';

const GoogleAnthosProjectNameEditor = ({
  projectName: projectNameProp = '',
  otherNames,
  heading,
  onChange,
  onClose,
}) => {
  const [errors, setErrors] = useState(undefined);
  const [projectName, setProjectName] = useState(projectNameProp);

  const _onSubmit = (addAnother) => {
    let errors = { ...errors };
    if (!errors) {
      errors = {};
    }

    let noErrors = true;
    if (!projectName) {
      errors.name = 'Required';
      noErrors = false;
    } else if (otherNames && otherNames.length) {
      if (otherNames.indexOf(projectName) > -1) {
        errors.name = 'Duplicate';
        noErrors = false;
      }
    }

    if (noErrors) {
      const submitErrors = onChange(projectName, addAnother);
      if (submitErrors) {
        setErrors(submitErrors);
      } else {
        setErrors(undefined);
      }
    } else {
      setErrors(errors);
    }
  };

  const _onChange = (event) => {
    let errors = { ...errors };
    if (!errors) {
      errors = {};
    }

    const attribute = event.target.getAttribute('name');
    const projectName = event.target.value;
    setProjectName(projectName);

    if (errors.hasOwnProperty(attribute)) {
      delete errors[attribute];
    }

    let noErrors = true;
    if (!projectName) {
      noErrors = false;
      errors.name = 'Required';
    } else if (otherNames && otherNames.length) {
      if (otherNames.indexOf(projectName) > -1) {
        noErrors = false;
        errors.name = 'Duplicate';
      }
    }

    if (noErrors) {
      setErrors(undefined);
    } else {
      setErrors(errors);
    }
  };

  return (
    <GLBMLayer
      position='right'
      closer={true}
      onClose={onClose}
      onEsc={onClose}
      onClickOutside={onClose}
      full='vertical'
      style={{ minWidth: '400px' }}
      title={heading}
    >
      <Box
        direction='column'
        flex={true}
        pad={{ horizontal: 'medium', vertical: 'small' }}
      >
        <FormField
          key='locationEditorId'
          htmlFor='locationEditorId'
          label='Project Name'
          error={errors?.name}
        >
          <TextInput
            id='locationId'
            name='name'
            value={projectName}
            onChange={_onChange}
          />
        </FormField>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box gap='small' direction='row'>
            <Button
              label='OK'
              id={IDUtil.getId('GoogleAnthosProjectNameEditorOKButton')}
              primary={true}
              onClick={() => _onSubmit(false)}
            />
            <Button
              label='Cancel'
              id={IDUtil.getId('GoogleAnthosProjectNameEditorCancelButton')}
              secondary={true}
              onClick={() => onClose()}
            />
          </Box>
          <Button
            plain={true}
            icon={<FormAdd />}
            label='Add Another'
            onClick={() => _onSubmit(true)}
            id={IDUtil.getId('GoogleAnthosProjectNameEditorAddAnotherButton')}
            a11yTitle='Add Another Location'
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

GoogleAnthosProjectNameEditor.propTypes = {
  heading: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  projectName: PropTypes.string,
  otherNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default GoogleAnthosProjectNameEditor;
