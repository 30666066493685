// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import { hasValue } from '../shared/util/BasicUtil';
import ServiceTypeStore from '../stores/ServiceTypeStore';
import { EquipmentIdColumn, TierOptions } from './model';

/**
 * Accepts config object (options.config) and returns the correct TierOption. This is to support
 * the fact that the API does not have a SINGLE TierOption, but we need to support one in the UI so it is inferred.
 * @param config
 * @returns {TierOptions|{TierListComponent, apiValue, tooltip, label, tiersLookupKey}}
 */
export function determineTierType({ tierType, serviceType }) {
  const tierTypeEnum = TierOptions.enumValueOf(tierType);
  const serviceTypeEnum = ServiceTypeStore.getService(serviceType);
  const hasSingleTierOptions = serviceTypeEnum.serviceOptions.tierOptions.includes(TierOptions.SINGLE);
  return tierTypeEnum === TierOptions.CUSTOM && hasSingleTierOptions ? TierOptions.SINGLE : tierTypeEnum;
}

export function configurableDeviceAndComponent(serviceType) {
  const [deviceOrComponent, component] = serviceType.inventoryManagement;
  return hasValue(deviceOrComponent) && hasValue(component);
}

/**
 * Lookup the column label for a given resource
 * @param resource: device or component
 * @param column: ServiceColumn enum
 * @param serviceType: ServiceType enum
 * @param map: map used for converting from an id to label (e.g. tiers, locations)
 */
export function getColumnLabel(resource, column, serviceType, map) {
  const field = column.field(serviceType);
  const value = resource.lookup(field);
  const useMap = hasValue(value) && hasValue(map) && hasValue(map[value]);
  /* map is used to translate id's to corresponding label */
  return useMap ? map[value] : value;
}

export function getEquipmentId(equipment, serviceType) {
  const equipmentColumn = serviceType.configureResources.distinctColumns.find(column => column instanceof EquipmentIdColumn);
  if (equipmentColumn) {
    return equipment.lookup(equipmentColumn.field(serviceType));
  }
  return undefined;
}

export function getComponentKeyField(serviceType) {
  const equipmentColumn = serviceType.configureComponents.distinctColumns.find(column => column instanceof EquipmentIdColumn);
  if (equipmentColumn) {
    return equipmentColumn.field(serviceType);
  }
  return undefined;
}

export function getServiceLabel(equipment, column, serviceType, map, fieldFn = serviceType => column.field(serviceType)) {
  const field = fieldFn(serviceType);
  const value = equipment.lookup(field);
  const useMap = hasValue(value) && hasValue(map) && hasValue(map[value]);
  /* map is used to translate id's to corresponding label */
  return useMap ? map[value] : value;
}
