// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Footer,
} from 'grommet';
import GLBMLayer from '../shared/component/GLBMLayer';
import { MinChargeInterval } from '../constants/MinChargeInterval';
import { SampleInterval } from '../constants/SampleInterval';
import { useServiceMeterCreateMutate, useServiceMeterUpdateMutate } from '../../core';
import ServiceMeterForm from './ServiceMeterForm';

const ServiceMeterEditor = ({
  onClose, canEdit, meter: initialMeter = undefined, serviceType: initialServiceType = undefined, ...props
}) => {
  const [meter, setMeter] = useState(initialMeter || {
    serviceType: initialServiceType,
    nonBillable: false,
    drilldownFields: [],
  });
  const isNew = !initialMeter;
  const [errors, setErrors] = useState({});

  const { mutate: createServiceMeter } = useServiceMeterCreateMutate();
  const { mutate: updateServiceMeter } = useServiceMeterUpdateMutate(meter?.id);

  const drilldownFieldsValidation = (field) => {
    if (!field) {
      return 'Required';
    }
    const v4 = new RegExp(/^[0-9a-zA-Z-_]{3,48}$/i);
    if (!v4.test(field)) {
      return 'Must be 3 to 48 digits, characters, dashes and underscores';
    }
    return undefined;
  };

  const refreshErrors = (meter) => {
    const errors = {};
    if (!meter.id ? errors.id = 'Required' : false);
    if (!meter.name ? errors.name = 'Required' : false);
    if (!meter.serviceType ? errors.serviceType = 'Required' : false);
    if (!meter.type ? errors.type = 'Required' : false);
    if (!meter.serviceCategory ? errors.serviceCategory = 'Required' : false);
    if (!meter.unitOfMeasure ? errors.unitOfMeasure = 'Required' : false);
    // if (!meter.nonBillable ?  errors.nonBillable= 'Required': false);
    if (!meter.drillDownType ? errors.drillDownType = 'Required' : false);
    if (meter.drilldownFields && meter.drilldownFields.some(el => drilldownFieldsValidation(el))) {
      errors.drilldownFields = 'fields';
    }

    if (meter.type === 'Allocation') {
      if (!meter.allocationMinChargeInterval ? errors.allocationMinChargeInterval = 'Required' : false);
      if (!meter.allocationRateInterval ? errors.allocationRateInterval = 'Required' : false);
      if (!meter.allocationAggregationInterval ? errors.allocationAggregationInterval = 'Required' : false);
      if (!meter.allocationSampleMethod ? errors.allocationSampleMethod = 'Required' : false);
    }

    if (meter.unitOfMeasure === 'Each') {
      if (!meter.unitName ? errors.unitName = 'Required' : false);
    }

    // Allocation Sample Method Rule 1
    if (meter.allocationSampleMethod === SampleInterval.Count.label) {
      if (
        meter.allocationMinChargeInterval !== MinChargeInterval.Day.label
        && meter.allocationMinChargeInterval !== MinChargeInterval.Month.label
      ) {
        errors.allocationSampleMethod = 'With Count Allocation Sample Method only Day and Month Allocation Min Charge Interval are allowed';
      }
    }

    // Allocation Sample Method Rule 2
    if (meter.allocationMinChargeInterval === MinChargeInterval.Month.label) {
      if (meter.allocationSampleMethod !== SampleInterval.Count.label) {
        errors.allocationSampleMethod = 'With Month Allocation Min Charge Interval only Count Allocation Sample Method is allowed';
      }
    }

    // validate name:
    if (meter.name) {
      const v4 = new RegExp(/^[0-9a-zA-Z-]{3,48}$/i);
      if (!v4.test(meter.name)) {
        errors.name = 'Name is invalid, must be 3 to 48 digits, characters and dashes';
      }
    }

    // validate that id is a valid UUIDv4:
    if (meter.id) {
      const v4 = new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/i);
      if (!v4.test(meter.id)) {
        errors.id = 'Not a valid UUIDv4';
      }
    }
    return errors;
  };

  const onChange = (newMeter, field) => {
    const newErrors = { ...errors };
    newErrors[field] = undefined;
    setMeter(newMeter);
    setErrors(newErrors);
  };

  const hasErrors = (meter) => {
    const errors = refreshErrors(meter);
    return Object.keys(errors).length > 0;
  };

  const onSubmit = () => {
    setErrors(refreshErrors(meter));
    if (hasErrors(meter)) {
      return;
    }
    if (isNew) {
      createServiceMeter(meter, {
        onSuccess: () => onClose(undefined, true)
      });
    } else {
      updateServiceMeter(meter, {
        onSuccess: () => onClose(undefined, true)
      });
    }
  };

  return (
    <GLBMLayer
      position='right'
      id='edit-layer'
      closer={true}
      flush={true}
      overlayClose={true}
      onEsc={onClose}
      onClickOutside={onClose}
      onClose={onClose}
      full='vertical'
      title={isNew ? 'Create new Meter' : canEdit ? 'Edit Meter' : 'View Meter'}
    >
      <Box
        pad='none'
        direction='column'
        flex={true}
        fill='vertical'
        style={{ width: '525px' }}
      >
        <Box flex={true} pad={{ 'horizontal': 'small' }} align='start'>
          <Box flex={true} fill='horizontal' direction='column' justify='between' overflow='auto'>
            <ServiceMeterForm
              meter={meter}
              errors={errors}
              onChange={onChange}
              readOnly={!canEdit}
              isNew={isNew}
              drilldownFieldsValidation={drilldownFieldsValidation}
            />
          </Box>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='start' gap='small'>
          {canEdit
            && (
              <Button
                label='Save'
                primary={true}
                onClick={onSubmit}
              />
            )}
          <Button label='Cancel' secondary={true} onClick={onClose} />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

ServiceMeterEditor.propTypes = {
  serviceType: PropTypes.string,
  meter: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default ServiceMeterEditor;
