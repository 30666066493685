// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import pluralize from 'pluralize';
import 'core-js/features/array/flat-map';
import 'intl-list-format';
import 'intl-list-format/locale-data/en';
import { hasValue } from './components/shared/util/BasicUtil';

/* Fix pluralize capital "S" bug */
const pluralizePluralFn = pluralize.plural;
pluralize.plural = function (...args) {
  let result = pluralizePluralFn(...args);
  if (result[result.length - 1] === 'S') {
    result = `${result.substring(0, result.length - 1)}s`;
  }
  return result;
};

pluralize.addPluralRule(/storage$/i, 'storage');
pluralize.addPluralRule(/utilization$/i, 'utilization');
pluralize.addPluralRule(/compute$/i, 'compute');

function advancedSort(ascending = true, valueLookupFn = val => val) {
  return this.sort((a, b) => {
    const aValue = valueLookupFn(a);
    const bValue = valueLookupFn(b);

    if ((!hasValue(aValue) && !hasValue(bValue)) || (aValue === bValue)) {
      return 0;
    } if ((!hasValue(aValue) && bValue) || (aValue > bValue)) {
      return ascending ? 1 : -1;
    }
    return ascending ? -1 : 1;
  });
}

function lookup(field, defaultValue) {
  try {
    return field.split('.').reduce((_object, _field) => _object[_field], this);
  } catch (e) {
    return defaultValue;
  }
}

function setDeep(path, value) {
  let schema = this; // a moving reference to internal objects within obj
  const pList = path.split('.');
  const len = pList.length;
  for (let i = 0; i < len - 1; i += 1) {
    const elem = pList[i];
    if (!schema[elem]) {
      schema[elem] = {};
    }
    schema = schema[elem];
  }

  schema[pList[len - 1]] = value;
}

/**
 * Need to assign to prototype directly for IDE code completion. DefineProperties needed to set property meta
 */


Array.prototype.advancedSort = advancedSort;
Object.defineProperties(Array.prototype, {
  advancedSort: {
    configurable: false,
    writable: false,
    enumerable: false,
    value: advancedSort,
  },
});

Object.prototype.lookup = lookup;
Object.defineProperties(Object.prototype, {
  lookup: {
    configurable: false,
    writable: false,
    enumerable: false,
    value: lookup,
  },
});

Object.prototype.setDeep = setDeep;
Object.defineProperties(Object.prototype, {
  setDeep: {
    configurable: false,
    writable: true,
    enumerable: false,
    value: setDeep,
  },
});
