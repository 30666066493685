// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Table,
  TableBody, TableCell,
  TableHeader,
  TableRow,
} from 'grommet';
import {
  Add,
  CaretUpFill,
  Contract,
  Copy,
  Edit,
  Expand, Trash,
} from 'grommet-icons';
import moment from 'moment';
import IDUtil from '../../../../shared/util/IDUtil';
import GLBMTooltip from '../../../../shared/component/GLBMTooltip';
import { StatusIcon } from '../../../../shared/component/StatusIcon';
import BaseContainer from './components/BaseContainer';
import MeterCapacitiesEditor from './MeterCapacitiesEditor';
import { requireInstalledCapacities } from './util';

const MeterCapacities = (props) => {
  const [layer, setLayer] = useState(undefined);
  const [selectedRevision, setSelectedRevision] = useState(undefined);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  const [lastEditedRevision, setLastEditedRevision] = useState(undefined);

  useEffect(() => {
    if (lastEditedRevision) setLastEditedRevision(undefined);
  }, [lastEditedRevision]);

  const showMaxInvoiceableCap = () => props.rate && props.rate.capacityRevisions && props.rate && props.rate.capacityRevisions.some(el => el.maximumInvoiceableCapacity);

  const onClick = (state) => {
    props.onToggleExpansion(state);
  };

  const getAddAction = () => {
    setLayer('add');
    setSelectedRevision(undefined);
    setSelectedIndex(undefined);
  };

  const getEditAction = (revision, index) => {
    setLayer('edit');
    setSelectedRevision(JSON.parse(JSON.stringify(revision)));
    setSelectedIndex(index);
  };

  const getCopyAction = (revision, index) => {
    setLayer('copy');
    setSelectedRevision(JSON.parse(JSON.stringify(revision)));
    setSelectedIndex(index);
  };

  const getDeleteAction = (index) => {
    const rate = { ...props.rate };
    rate.capacityRevisions.splice(index, 1);
    props.onChange(rate);
  };

  const getActions = () => {
    const { expanded } = { ...props };
    const toggle = (expanded === 'NONE' || expanded !== 'CAPACITY'
      ? (
        <Button
          key='expand'
          icon={<Expand />}
          onClick={() => onClick('CAPACITY')}
          hoverIndicator={true}
          kind='toolbar'
        />
      )
      : (
        <Button
          key='collapse'
          icon={<Contract />}
          onClick={() => onClick('NONE')}
          hoverIndicator={true}
          kind='toolbar'
        />
      ));
    const addNew = (!props.rate || props.readOnly ? undefined : (
      <Button
        key='edit'
        icon={<Add />}
        label='Add Revision'
        id={IDUtil.getId('BillingInfoEditorAddRevisionCapacities')}
        onClick={getAddAction}
        hoverIndicator={true}
        kind='toolbar'
      />
    ));

    return ([
      addNew,
      // toggle,
    ]);
  };

  const isTieredRate = () => props?.rate?.tierType === 'TIERED';

  const getColumns = () => {
    let columns = isTieredRate() ? ['Status', 'Start Date'] : ['Start Date'];
    if (requireInstalledCapacities(props.serviceType)) {
      columns.push('Installed Capacity');
    }
    columns = columns.concat(['Requested Cap.', 'Reserved %', 'Reserved Capacity.', 'Adjusted Reserved Cap.']);
    if (showMaxInvoiceableCap()) {
      columns.push('Max. Invoiceable Capacity.');
    }
    if (!props.readOnly) {
      columns.push('Action');
    }
    return columns.map(column => (
      <TableCell scope='col' border='bottom'>
        {column}
      </TableCell>
    ));
  };

  const formatDate = date => new Date(date).toLocaleDateString((window.navigator.languages) ? window.navigator.languages[0] : (window.navigator.userLanguage || window.navigator.language), { timeZone: 'UTC' });

  const formatNumber = (x) => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  const changeElement = (current, previous, property) => {
    let changeElement;
    if (current && previous) {
      if (current[property] > previous[property]) {
        changeElement = (<CaretUpFill style={{ paddingTop: '5px' }} color='black' size='small' />);
      } else if (current[property] < previous[property]) {
        changeElement = (<CaretUpFill style={{ paddingTop: '5px' }} color='black' size='small' />);
      }
    }
    return (
      <span
        style={{
          display: 'inline-block', marginLeft: '-18px', minWidth: '18px',
        }}
      >
        {changeElement}
&nbsp;
      </span>
    );
  };

  const getMinContractualCommit = (r) => {
    if (!r.hasOwnProperty('minimumContractualCapacity') || r.minimumContractualCapacity === 0 || r.resetMinContractualCapacity) {
      return (r.committedCapacityOverrideEnabled ? (r.committedCapacityOverride || 0) : (r.committedCapacity || 0));
    }
    return (r.minimumContractualCapacity || 0).toFixed(2);
  };

  const getMaximumInvoiceableCapacity = (r) => {
    if (r.hasOwnProperty('maximumInvoiceableCapacity') && r.maximumInvoiceableCapacity !== undefined) {
      return formatNumber(parseFloat(Math.round((r.maximumInvoiceableCapacity) * 1000) / 1000));
    }
    return undefined;
  };

  const getStatus = (currentCapacity) => {
    const failedCapacities = [];
    props.rate?.rateRevisions?.forEach((currentRate) => {
      const { effectiveDate: rateEffectiveDate } = currentRate;
      const foundCapacity = props.rate?.capacityRevisions?.find((cap, index, allCapacities) => {
        const currentEffectiveDate = cap?.effectiveDate;
        const nextEffectiveDate = allCapacities[index + 1]?.effectiveDate;
        return moment(currentEffectiveDate, 'YYYY-MM-DD').isSameOrBefore(moment(rateEffectiveDate, 'YYYY-MM-DD')) && (!nextEffectiveDate || moment(nextEffectiveDate, 'YYYY-MM-DD').isAfter(moment(rateEffectiveDate, 'YYYY-MM-DD')));
      });
      const limit = foundCapacity?.committedCapacityOverrideEnabled ? foundCapacity?.committedCapacityOverride : foundCapacity?.committedCapacity;
      const formattedLimit = limit && (typeof limit === 'string') ? +limit : limit;
      Object.keys(currentRate.tiers).forEach((tier) => {
        if (formattedLimit && (+tier >= formattedLimit)) {
          failedCapacities.push(foundCapacity?.effectiveDate);
        }
      });
    });
    return failedCapacities.includes(currentCapacity.effectiveDate) ? (
      <GLBMTooltip content='The Reserved Capacity for this revision is lower than one or more start ranges for the pricing bands in the corresponding Rate revision.  Please increase the Reserved Capacity or remove the offending pricing bands to fix this issue.'>
        <Box><StatusIcon value='critical' size='small' /></Box>
      </GLBMTooltip>
    ) : <StatusIcon value='ok' size='small' />;
  };

  const getRows = () => {
    let prevCapacity;
    if (props.rate && props.rate.capacityRevisions) {
      return props.rate.capacityRevisions.sort((c1, c2) => {
        const momentA = moment(c1.effectiveDate, 'YYYY-MM-DD');
        const momentB = moment(c2.effectiveDate, 'YYYY-MM-DD');
        if (momentA > momentB) return 1;
        if (momentA < momentB) return -1;
        return 0;
      }).map((r, index) => {
        const minConCom = getMinContractualCommit(r);
        const maxInvCap = getMaximumInvoiceableCapacity(r);

        const row = (
          <TableRow
            key={index}
            className={`rate-card-revision ${r === lastEditedRevision && 'edited'}`}
            id={IDUtil.getId('Capacity', index)}
          >
            {isTieredRate() ? (
              <TableCell>{getStatus(r)}</TableCell>
            ) : undefined}
            <TableCell scope='row' key={`startDate_${index}`} id={IDUtil.getId('CapacityStartDate', index)}>{formatDate(r.effectiveDate)}</TableCell>
            {requireInstalledCapacities()
            && (
            <TableCell key={`installedCapacity_${index}`} id={IDUtil.getId('InstalledCapacity', index)}>
              <Box direction='row' align='center'>
                {changeElement(r, prevCapacity, 'installedCapacity')}
                {formatNumber(parseFloat(Math.round((r.installedCapacity || 0) * 1000) / 1000))}
              </Box>
            </TableCell>
            )}
            <TableCell key={`requestedCapacity${index}`} id={IDUtil.getId('RequestedCapacity', index)}>
              <Box direction='row' align='center'>
                {changeElement(r, prevCapacity, 'requestedCapacity')}
                {formatNumber(parseFloat(Math.round(r.requestedCapacity * 1000) / 1000))}
              </Box>
            </TableCell>
            <TableCell key={`committedPct_${index}`} id={IDUtil.getId('CommitedPct', index)}>
              <Box direction='row' align='center'>
                {changeElement(r, prevCapacity, 'committedPct')}
                {r.committedPct}
              </Box>
            </TableCell>
            {!r.committedCapacityOverrideEnabled
          && (
          <TableCell key={`committedCapacity_${index}`} id={IDUtil.getId('CommitedCapacity', index)}>
            <Box direction='row'>
              {changeElement(r, prevCapacity, 'committedCapacity')}
              {formatNumber(parseFloat(Math.round(r.committedCapacity * 1000) / 1000).toFixed(2))}
            </Box>
          </TableCell>
          )}
            {r.committedCapacityOverrideEnabled
          && (
          <TableCell key={`committedCapacityOverride_${index}`} id={IDUtil.getId('CommitedCapacityOverride', index)}>
            <Box direction='row'>
              {changeElement(r, prevCapacity, 'committedCapacityOverride')}
              {formatNumber(parseFloat(Math.round(r.committedCapacityOverride * 1000) / 1000))}
              <sup>override</sup>
            </Box>
          </TableCell>
          )}
            <TableCell key={`minConCom_${index}`} id={IDUtil.getId('MinConCom', index)}>
              <GLBMTooltip
                content={(
                  <div>
                    Adjusted Reserved Capacity is based on Reserved Capacity, Actual Usage from previous months, and
                    shrink rules or other billing model rules. This value will be calculated after the rate plan has been
                    saved.
                  </div>
)}
              >
                <span
                  className='dotted-tooltip'
                >
                  {formatNumber(parseFloat(Math.round((minConCom) * 1000) / 1000))}
                </span>
              </GLBMTooltip>
            </TableCell>
            {showMaxInvoiceableCap() && (
            <TableCell key={`maxInvCap_${index}`} id={IDUtil.getId('MaxInvCap', index)}>
              <span>
                {maxInvCap || '-'}
              </span>
            </TableCell>
            )}
            {!props.readOnly
          && (
          <TableCell key={`actions_${index}`} align='start' id={IDUtil.getId('CapacitiesActions', index)}>
            <Box direction='row' gap='medium' align='center'>
              <Button
                size='large'
                id={IDUtil.getId('CapacitiesListItemEditButton', index)}
                icon={<Edit style={{ height: '18px', width: '18px' }} />}
                onClick={() => getEditAction(r, index)}
                style={{ padding: '0' }}
              />
              <Button
                size='large'
                id={IDUtil.getId('CapacitiesListItemCopyButton', index)}
                icon={<Copy style={{ height: '18px', width: '18px' }} />}
                onClick={() => getCopyAction(r, index)}
                style={{ padding: '0' }}
              />
              <Button
                size='large'
                id={IDUtil.getId('CapacitiesListItemDeleteButton', index)}
                icon={<Trash style={{ height: '18px', width: '18px' }} />}
                disabled={props.rate.capacityRevisions.length === 1}
                onClick={() => getDeleteAction(index)}
                style={{ padding: '0' }}
              />
            </Box>
          </TableCell>
          )}
          </TableRow>
        );
        prevCapacity = r;
        return row;
      });
    }
    return [];
  };

  const canEdit = () => {
    if (!props.permissions) {
      return true;
    }
    return props.permissions.canEditRates;
  };

  const onLayerClose = () => {
    setLayer(undefined);
    setSelectedRevision(undefined);
    setSelectedIndex(undefined);
  };

  const onLayerChange = (revision) => {
    const rate = { ...props.rate };

    if (layer === 'add' || layer === 'copy') {
      rate.capacityRevisions.push(revision);
    } else if (layer === 'edit') {
      rate.capacityRevisions[selectedIndex] = revision;
    }

    onLayerClose();
    props.onChange(rate);

    setLastEditedRevision(revision);
  };

  const getDefaultRevision = () => {
    const revision = {
      effectiveDate: undefined,
      committedCapacity: 0.0,
      committedCapacityOverrideEnabled: false,
      requestedCapacity: 0.0,
      committedPct: 0.0,
    };

    if (requireInstalledCapacities()) {
      revision.installedCapacity = 0.0;
    }

    return revision;
  };

  const renderLayer = () => {
    const { customer, meter, rate } = props;

    const startDates = (rate ? rate.capacityRevisions : []).reduce(
      (map, revision) => {
        map.push(revision.effectiveDate);
        return map;
      },
      [],
    );

    if (layer === 'add') {
      return (
        <MeterCapacitiesEditor
          onClose={onLayerClose}
          revision={getDefaultRevision()}
          onChange={onLayerChange}
          canEdit={canEdit()}
          preferences={customer.preferences}
          meter={meter}
          startDates={startDates}
          serviceType={props.serviceType}
          userType={props.userType}
        />
      );
    } if ((layer === 'edit' || layer === 'copy') && selectedRevision) {
      const customer = { ...props.customer };

      if (layer === 'edit') {
        startDates.splice(startDates.indexOf(selectedRevision.effectiveDate), 1);
      } else {
        selectedRevision.effectiveDate = undefined;
      }

      return (
        <MeterCapacitiesEditor
          onClose={onLayerClose}
          revision={selectedRevision}
          onChange={onLayerChange}
          canEdit={canEdit()}
          preferences={customer.preferences}
          meter={meter}
          startDates={startDates}
          serviceType={props.serviceType}
          userType={props.userType}
        />
      );
    }
  };

  return (
    <BaseContainer
      title='Capacities'
      actions={getActions()}
      expanded={props.expanded === 'NONE' || props.expanded === 'CAPACITY'}
      data-testid='capacities-container'
    >
      <Box
        direction='column'
        fill='vertical'
        data-e2e='capacities'
      >
        <Table
          responsive={false}
          scrollable={true}
          className='narrow-table'
        >
          <TableHeader
            sortIndex={0}
            sortAscending={true}
          >
            <TableRow>
              {getColumns()}
            </TableRow>
          </TableHeader>
          <TableBody>
            {getRows()}
          </TableBody>
        </Table>
      </Box>
      {renderLayer()}
    </BaseContainer>
  );
};

export default MeterCapacities;
