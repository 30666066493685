// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import map from 'lodash/map';
import filter from 'lodash/filter';
import BaseContainer from './components/BaseContainer';

const LocationInfo = ({
  expanded,
  setExpanded,
  equipment = [],
  locationId,
}) => {
  const equipmentIds = useMemo(() => (
    map(filter(equipment, ['locationId', locationId]), 'equipmentId').sort()
  ), [equipment, locationId]);

  return (
    <BaseContainer
      title='Location Attributes'
      fill={expanded === 'ATTRS'}
      expanded={expanded === 'NONE' || expanded === 'ATTRS'}
      data-testid='location-attributes-container'
      /* actions={expanded === 'NONE' || expanded !== 'ATTRS' ? [
        <Button
          key='expand'
          icon={<Expand />}
          onClick={() => setExpanded('ATTRS')}
        />
      ] : [
        <Button key='collapse' icon={<Contract />} onClick={() => setExpanded('NONE')} />
      ]} */
    >
      <Box pad={{ horizontal: 'xsmall' }} overflow='auto' height={{ max: expanded === 'ATTRS' ? undefined : 'medium' }}>
        <Text weight='bold' margin={{ bottom: 'xsmall' }}>Service Instance IDs</Text>
        {map(equipmentIds, id => (
          <Text key={id}>{id}</Text>
        ))}
        {equipmentIds.length === 0 && (
          <Text color='text-weak'>(none)</Text>
        )}
      </Box>
    </BaseContainer>
  );
};

LocationInfo.propTypes = {
  expanded: PropTypes.string.isRequired,
  setExpanded: PropTypes.func.isRequired,
  equipment: PropTypes.array,
  locationId: PropTypes.string.isRequired,
};

export default LocationInfo;
