// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, {
  createContext,
  useContext,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { useCustomersQuery } from '../../core';

const Context = createContext([null, () => { }]);

export const SelectedCustomerIdContextProvider = ({ children }) => {
  const [id, setId] = useState(undefined);
  const val = useMemo(() => [id, setId], [id, setId]);
  return (
    <Context.Provider value={val}>
      {children}
    </Context.Provider>
  );
};

SelectedCustomerIdContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

SelectedCustomerIdContextProvider.defaultProps = {
};

export const useSelectedCustomerId = () => useContext(Context);

// Get the customer def from list of all customers that is currently selected
export const useSelectedCustomerInfo = () => {
  const { data } = useCustomersQuery();
  const [selectedId] = useSelectedCustomerId();
  return useMemo(() => find(data, ['id', selectedId]), [data, selectedId]);
};
