// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Toast from './Toast';

const ToastContainer = ({ toasts = [] }) => (
  <>
    {
        toasts && toasts.map(e => <Toast toast={e.toast} key={e.id} id={e.id} title={e.title} message={e.message} status={e.status} global={e.global} icon={e.icon} />)
      }
  </>
);

ToastContainer.propTypes = {
  toasts: PropTypes.array,
};

export default ToastContainer;
