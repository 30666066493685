// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import { Box } from 'grommet';
import GLBMHeading from '../../../../../shared/component/GLBMHeading';

const BaseContainer = props => (
  <Box
    direction='column'
    flex={false}
    data-testid={props?.['data-testid']}
  >
    <Box>
      <GLBMHeading
        title={props?.title}
        actions={props?.actions}
        pad={{ vertical: 'none', horizontal: 'small' }}
        height='42px'
        level='3'
        inline={true}
      />
    </Box>
    {props?.expanded
        && (
        <Box pad='xsmall' overflow='auto'>
          {props?.children}
        </Box>
        )}
  </Box>
);

export default BaseContainer;
