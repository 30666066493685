// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext, useState } from 'react';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import moment from 'moment';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { ApiContext } from '../../AppContext';

import { PartnerType } from '../../components/shared/constants/PartnerType';
import { useErrorToast } from '../../components/shared/hooks';

import {
  executeDelete, executeGet, executePost, executePut
} from '../api';
import { useServiceEditorContext, useValidationContext } from '../../components/services/service-edit/contexts/ServiceEditorContext';

export const useCustomerServicesQuery = (customerId, options) => {
  const path = useContext(ApiContext)('services.list.path', { customerId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:SERVICE-LIST', customerId],
    queryFn: () => executeGet(path),
    gcTime: 0,
    ...(options || []),
  }), 'Fetch Services Error');
};

export const useCustomerServiceQuery = (customerId, serviceType, options) => {
  const path = useContext(ApiContext)('services.read.path', { customerId, serviceType });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:SERVICE-TYPE', customerId, serviceType],
    queryFn: () => executeGet(path),
    gcTime: 0,
    ...(options || []),
  }), 'Fetch Services Error');
};

const pruneRates = (meters, rates) => rates.reduce((acc, rate) => {
  if (meters.filter(m => m.id === rate.meterId).length > 0) {
    acc.push(rate);
  }
  return acc;
}, []);

const pruneLocationRates = (meters, locationRates) => locationRates.filter(r => !!meters.find(m => m.locationId === r.locationId));

const pruneMappings = (dirtyEquipment) => {
  const mappings = dirtyEquipment.map(device => device.data);
  mappings.forEach((equipment) => {
    // eslint-disable-next-line no-param-reassign
    equipment.status = 'COMPLETE';
  });
  return mappings;
};

const sortComponentsByEquipment = (drives) => {
  const result = {};
  drives.forEach((drive) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!result.hasOwnProperty(drive.equipmentId)) {
      result[drive.equipmentId] = [];
    }
    result[drive.equipmentId].push(drive);
  });
  return result;
};

export const useCustomerServicesUpdateMutate = (customerId, serviceType, options) => {
  // const queryClient = useQueryClient();
  const contextFn = useContext(ApiContext);
  const queryClient = useQueryClient();

  // fixed paths:
  const optionsPath = contextFn('services.update.options.path', { customerId, serviceType });
  const ratesPath = contextFn('services.update.rates.path', { customerId, serviceType });
  const mappingsPath = contextFn('services.update.mappings.path', { customerId, serviceType });
  // mutation functions:
  const optionsMutate = useMutation({
    mutationFn: payload => executePut(optionsPath, payload), onSuccess: () => queryClient.removeQueries({
      queryKey: ['GLBM:GET-SERVICE', customerId, serviceType]
    })
  });
  const ratesMutate = useMutation({
    mutationFn: payload => executePost(ratesPath, payload), onSuccess: () => queryClient.removeQueries({
      queryKey: ['GLBM:SERVICE-TYPE-RATES', customerId, serviceType, 'MASTER']
    })
  });
  const mappingsMutate = useMutation({ mutationFn: payload => executePut(mappingsPath, payload) });
  const componentMutate = useMutation({
    mutationFn: (payload) => {
      const drive = payload[0]; // use the first drive to get to equipmentId
      const drivesPath = contextFn('services.update.drives.path', { customerId, serviceType, equipmentId: drive.equipmentId });
      return executePut(drivesPath, payload);
    }
  });
  const partnerRatesMutate = useMutation({
    mutationFn: ({
      tenant,
      payload
    }) => {
      const partnerRatesPath = contextFn('services.update.partnerRates.path', {
        customerId,
        serviceType
      }, { view: tenant });
      return executePost(partnerRatesPath, payload);
    }
  });

  // main mutation function:
  const mutate = async (editorData) => {
    try {
      const promises = [];
      const {
        canEditOptions, canEditRates, canEditMappings, canMarkupRates
      } = editorData.permissions;
      const { tenant } = editorData.context;

      if (canEditOptions || canEditRates) {
        promises.push(optionsMutate.mutateAsync(editorData.options));
      }

      if (canEditMappings) {
        // top level equipment:
        promises.push(mappingsMutate.mutateAsync(pruneMappings(editorData.dirtyEquipment)));

        // components such as 3par drives:
        const sortedByEquipment = sortComponentsByEquipment(editorData.dirtyComponents);
        Object.keys(sortedByEquipment)
          .map(x => sortedByEquipment[x])
          .map(drives => promises.push(componentMutate.mutateAsync(drives)));
      }

      if (canEditRates || canMarkupRates) {
        switch (tenant) {
          case PartnerType.RESELLER.enumKey:
          case PartnerType.DISTRIBUTOR.enumKey:
            promises.push(partnerRatesMutate.mutateAsync({ tenant, payload: pruneRates(editorData.meters, editorData.rates) }));
            break;
          default:
            promises.push(ratesMutate.mutateAsync({
              meterRates: pruneRates(editorData.meters, editorData.rates),
              locationRates: pruneLocationRates(editorData.meters, editorData.locationRates),
            }));
            break;
        }
      }

      // Wait for all promises to settle
      const results = await Promise.allSettled(promises);
      const hasFailed = results.some(result => result.status === 'rejected');

      if (hasFailed) {
        options.onError('One or more save calls failed');
      } else {
        options.onSucces();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  return {
    options: optionsMutate,
    mappings: mappingsMutate,
    component: componentMutate,
    partnerRates: partnerRatesMutate,
    rates: ratesMutate,
    mutate,
  };
};

const prepareEquipment = (equipment, components) => {
  const results = [];

  if (equipment && equipment.length) {
    for (let i = 0; i < equipment.length; i += 1) {
      const e = equipment[i].data;
      const drives = [];
      if (components && components.length) {
        for (let j = 0; j < components.length; j += 1) {
          const c = components[j];
          if (c.equipmentId === e.equipmentId) {
            drives.push(c);
          }
        }
        if (drives.length > 0) {
          e.details = {
            type: e.type,
            drives,
          };
        }
      }
      results.push(e);
    }
  }

  return results;
};

export const useUnconfiguredEquipmentQuery = (customerId, serviceType, serviceOptions, equipment, components, originalServiceOptions, options) => {
  const path = useContext(ApiContext)('services.validation.unconfiguredEquipment.path', { customerId, serviceType });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:SERVICE-UNCONFIGURED-EQUIPMENT', customerId, serviceType],
    queryFn: () => executePost(path, {
      service: serviceOptions,
      equipment: prepareEquipment(equipment, components),
      newService: (originalServiceOptions.status === 'NEW'),
      verbose: true,
    }),
    refetchOnMount: true,
    ...(options || []),
  }), 'Validate Unconfigured Equipment Error');
};

export const useServiceFieldQuery = (customerId, serviceType, options) => {
  const path = useContext(ApiContext)('services.fields.path', { customerId, serviceType });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:SERVICE-TYPE-FIELDS', customerId, serviceType],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Fields Error');
};

export const useServiceMeterAndRatesQuery = (customerId, serviceType, serviceOptions, tenant, options) => {
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useQueries({
    queries: [
      {
        queryKey: ['GLBM:SERVICE-TYPE-METERS', customerId, serviceType],
        queryFn: () => {
          const path = contextFn('services.meters.path', { customerId, serviceType });
          return executePost(path, serviceOptions);
        },
        gcTime: 0,
        ...(options || {}),
        onError: ((err) => {
          addToast({
            status: 'critical',
            title: 'Fetch Service Type Meters Error',
            message: err?.response?.data?.message || err?.message,
          });
          options?.onError?.();
        }),
      },
      {
        queryKey: ['GLBM:SERVICE-TYPE-RATES', customerId, serviceType, tenant],
        queryFn: () => {
          if (tenant === 'MASTER') {
            const path = contextFn('services.rates.path', { customerId, serviceType });
            return executeGet(path);
          }
          const path = contextFn('services.partnerRates.path', { customerId, serviceType, tenant });
          return executeGet(path);
        },
        gcTime: 0,
        ...(options || {}),
        onError: ((err) => {
          addToast({
            status: 'critical',
            title: 'Fetch Service Type Rates Error',
            message: err?.response?.data?.message || err?.message,
          });
          options?.onError?.();
        }),
      }
    ]
  });
};

export const useServicePromoteMutate = (customerId, serviceType, options) => {
  const path = useContext(ApiContext)('services.promote.path', { customerId, serviceType });
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: (_data) => {
      queryClient.removeQueries({
        queryKey: ['GLBM:SERVICE-LIST', customerId]
      });
      options?.onSuccess?.(_data);
    }
  });
};

export const useAzureStackCSPListQuery = (options) => {
  const path = useContext(ApiContext)('services.azure-stack-csp.path');
  return useErrorToast(useQuery({
    queryKey: ['GLBM:AZURE-STACK-CSP'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Unable to load service info');
};

export const useAzureStackCustomerListQuery = (cspId, options) => {
  const path = useContext(ApiContext)('services.azure-stack-customer.path', { cspId });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:AZURE-STACK-CUSTOMERS'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Unable to load Azure Customers');
};

export const useSaveServiceExtensionMutation = (options) => {
  const contextFn = useContext(ApiContext);
  const { addToast } = useToast();
  return useMutation({
    mutationFn: ({ customerId, serviceType }) => {
      const path = contextFn('services.service-extensions.update', { customerId, serviceType });
      return executePost(path);
    },
    ...options,
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Unable to save Service Extension',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};

export const useSaveServiceExtensionsMutation = (customerId, serviceTypes) => {
  const [isSaving, setIsSaving] = useState(false);
  const [error, setErrors] = useState([]);
  const [success, setSuccess] = useState([]);
  const [isSaved, setIsSaved] = useState(false);

  const { mutate, ...rest } = useSaveServiceExtensionMutation({
    onSuccess: ({ serviceType }) => {
      setSuccess([...success, serviceType]);
    },
    onError: ({ serviceType }) => {
      setErrors([...error, serviceType]);
    }
  });

  const mutateAll = async () => {
    const queries = serviceTypes.map(serviceType => mutate({ customerId, serviceType }));
    setIsSaving(true);
    await Promise.all(queries);
    setIsSaving(false);
    setIsSaved(true);
  };

  const invalidateServiceExtensions = () => {
    setIsSaving(false);
    setIsSaved(false);
    setErrors([]);
    setSuccess([]);
  };

  return {
    mutate: mutateAll, ...rest, isSaving, isSaved, error, success, invalidateServiceExtensions
  };
};

export const useGetServiceQuery = (customerId, serviceId, options) => {
  const path = useContext(ApiContext)('services.service.read.path', { customerId, serviceId });
  return useQuery({
    queryKey: ['GLBM:GET-SERVICE', customerId, serviceId],
    queryFn: () => executeGet(path),
    ...(options || []),
    select: (data) => {
      const results = { ...data };
      // sort the service effective dates:
      if (results.config && results.config.effectiveDates) {
        let { effectiveDates } = results.config;
        if (effectiveDates && effectiveDates.length) {
          effectiveDates = effectiveDates.sort((a, b) => {
            const aDate = moment(a);
            const bDate = moment(b);
            return (aDate.isBefore(bDate) ? -1 : 1);
          });
          results.config.effectiveDates = effectiveDates;
        }
      }
      return results;
    },
  });
};

export const useDeleteServiceMutation = (customerId, options) => {
  const contextFn = useContext(ApiContext);
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  return useMutation({
    mutationFn: ({ customerId, serviceId }) => {
      const path = contextFn('services.service.delete.path', { customerId, serviceId });
      return executeDelete(path);
    },
    ...options,
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['GLBM:SERVICE-LIST', customerId]
      });
      options?.onSuccess?.();
    },
    onError: ((err) => {
      addToast({
        status: 'critical',
        title: 'Unable to delete service',
        message: err?.response?.data?.message || err?.message,
      });
      options?.onError?.();
    }),
  });
};

export const useValidationMutation = (step, opts) => {
  const [state] = useServiceEditorContext();
  const { receiveValidation } = useValidationContext();
  const {
    options, dirtyEquipment, dirtyComponents, originalOptions
  } = state;
  const { customerId, serviceType } = state.options;
  const path = useContext(ApiContext)('services.validation.unconfiguredEquipment.path', { customerId, serviceType });
  return useMutation({
    mutationFn: () => executePost(path, {
      service: options,
      equipment: prepareEquipment(dirtyEquipment, dirtyComponents),
      newService: (!!(originalOptions && originalOptions.status === 'NEW')),
      verbose: false,
    }),
    ...opts,
    onSuccess: json => receiveValidation(step, state, customerId, serviceType, json),
    onError: ((error) => {
      receiveValidation(step, state, customerId, serviceType, undefined, error);
      opts?.onError?.();
    }),
  });
};

export const useServiceComponentsQuery = (customerId, serviceId, equipmentId, equipmentType, options) => {
  const path = useContext(ApiContext)('services.equipment.component.path', {
    customerId, serviceId, equipmentId, equipmentType
  });
  return useErrorToast(useQuery({
    queryKey: ['GLBM:SERVICE-EQUIPMENT-COMPONENTS', customerId, serviceId, equipmentId, equipmentType],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Unable to load Equipment Components');
};
