// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Text } from 'grommet';
import { useQueryExtractAndDownload } from '../../../core';

const ExtractRow = ({ action }) => {
  const [showExecParams, setShowExecParams] = useState(false);
  const hasInputs = action.type === 'DYNAMIC';
  const ComponentToRender = action.inputs;
  const { mutate: extract, isPending: showSpinner } = useQueryExtractAndDownload(action.apiPath);

  return (
    <Box
      data-e2e='action-item'
      justify='between'
      flex={false}
      direction='row'
      border='top'
      pad='small'
      align='center'
    >
      <Box align='start' flex={true}>
        <Text data-e2e='main-text' weight='bold'>{action.name}</Text>
        <Text data-e2e='sub-text'>{action.description}</Text>
      </Box>
      <Box direction='row' align='center'>
        { hasInputs ? (
          <Button
            id={`extract-${action.type}-${action.name}`}
            data-testid={`extract-${action.type}-${action.name}`}
            label='Configure'
            onClick={() => setShowExecParams(true)}
            secondary={true}
            style={{ width: '120px' }}
          />
        ) : (
          <Button
            id={`extract-${action.type}-${action.name}`}
            data-testid={`extract-${action.type}-${action.name}`}
            label='Extract'
            onClick={() => extract()}
            secondary={true}
            busy={showSpinner}
            style={{ width: '120px' }}
          />
        )}
      </Box>
      {showExecParams && (
        <ComponentToRender
          action={action}
          onClose={() => setShowExecParams(false)}
        />
      )}
    </Box>
  );
};

ExtractRow.propTypes = {
  action: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    inputs: PropTypes.elementType,
    apiPath: PropTypes.string,
  }).isRequired
};

export default ExtractRow;
