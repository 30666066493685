// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Menu,
} from 'grommet';
import {
  Help, User,
} from 'grommet-icons';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import IDUtil from '../shared/util/IDUtil';
import AuthActions from '../actions/AuthActions';
import { insertIf } from '../shared/util/BasicUtil';
import { isUnassignedRole } from '../stores/UserTypeStore';

const SidebarFooter = ({ me }) => {
  const navigate = useNavigate();
  const { oktaAuth } = useOktaAuth();

  const signOut = async () => {
    const idToken = await oktaAuth.tokenManager.get('idToken');
    const accessToken = await oktaAuth.tokenManager.get('accessToken');
    await AuthActions.logout(isUnassignedRole(me?.role));
    await oktaAuth.signOut({ idToken, accessToken });
  };

  const menuItems = [
    ...insertIf(!isUnassignedRole(me?.role), [{
      label: 'Request Access',
      onClick: () => navigate('/requestrole'),
    }]),
    { label: 'Sign Out', onClick: () => { signOut(); } },
  ];

  return (
    (
      <Box direction='row' height='48px'>
        <Menu
          color='#425563'
          dropBackground='light-3'
          dropProps={{ align: { bottom: 'bottom', left: 'left' } }}
          responsive={true}
          inline={false}
          primary={true}
          id={IDUtil.getId('LeftNavUserMenu')}
          icon={<User />}
          label={(me ? (`${me.firstName} ${me.lastName}`.replace(/(.{20})..+/, '$1…')) : '')}
          items={menuItems}
        />
        <Button
          icon={<Help />}
          href='https://www.hpe.com/greenlake/hpe-glbm-user-guide-internal'
          target='_new'
          data-testid='help-url'
        />
      </Box>
    )
  );
};

SidebarFooter.propTypes = {
  me: PropTypes.object.isRequired,
};

export default SidebarFooter;
