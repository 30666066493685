// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import { Box, Button, Main } from 'grommet';
import { Add, Refresh } from 'grommet-icons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAccountKeyActivateMutate,
  useAccountKeysQuery,
  useCustomerQuery,
} from '../../core';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMSearch from '../shared/component/GLBMSearch';
import {
  SearchTextContextProvider
} from '../shared/component/HighlightUsingContext';
import { pagePermissions } from '../shared/constants/Permissions';
import { usePermissionChecker } from '../shared/hooks';
import { insertIf } from '../shared/util/BasicUtil';
import IDUtil from '../shared/util/IDUtil';
import EncryptionKeyCreateDialog from './dialogs/EncryptionKeyCreateDialog';
import EncryptionKeyDeleteDialog from './dialogs/EncryptionKeyDeleteDialog';
import EncryptionKeyEditDialog from './dialogs/EncryptionKeyEditDialog';
import EncryptionKeyTable from './EncryptionKeyTable';
import { AccountKey } from './types';

const EncryptionKeyListPage: React.FC = () => {
  const { customerId } = useParams();
  const navigate = useNavigate();
  const { hasPermissions } = usePermissionChecker();

  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [layer, setLayer] = useState<string | undefined>(undefined);
  const [selectedAccountKey, setSelectedAccountKey] = useState<AccountKey | undefined>(undefined);

  const onEditAccountKey = (accountKey: AccountKey) => {
    setLayer('edit');
    setSelectedAccountKey(accountKey);
  };

  const onDeleteAccountKey = (accountKey: AccountKey) => {
    setLayer('deleteConfirm');
    setSelectedAccountKey(accountKey);
  };

  const {
    data: customer,
    isFetching: customerFetching,
    refetch: refetchCustomer,
  } = useCustomerQuery(customerId, {
    enabled: !!customerId,
  });

  const {
    data: accountKeys,
    isFetching: keysFetching,
    refetch: refetchKeys,
  } = useAccountKeysQuery(customerId, {
    enabled: !!customerId,
  });

  const {
    mutate: activateAccountKey,
  } = useAccountKeyActivateMutate(customerId);

  const onActivateAccountKey = (accountKey: AccountKey) => {
    // @ts-ignore
    activateAccountKey({ keyId: accountKey.keyId, active: accountKey.active });
  };

  const filteredKeys = useMemo(() => accountKeys?.filter(({ name, createdBy }: AccountKey) => !searchText || name?.toLowerCase().includes(searchText.toLowerCase())
    || createdBy?.toLowerCase().includes(searchText.toLowerCase())) || [], [accountKeys, searchText]);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        back='/customers'
        title={`Metering Encryption: ${customer ? `${customer.name} (${customer.id})` : ''}`}
        search={(
          <GLBMSearch
            value={searchText}
            onChange={setSearchText}
          />
        )}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            onClick={() => {
              refetchCustomer();
              refetchKeys();
            }}
            a11yTitle='Refresh Account Keys'
            id={IDUtil.getId('ListViewToolbarRefreshButton')}
            key='refreshButton'
            label='Refresh'
            busy={keysFetching || customerFetching}
          />,
          ...insertIf(hasPermissions(pagePermissions.customers.view.encryptionKey.actions.add), [
            <Button
              kind='toolbar'
              icon={<Add />}
              label='Add'
              onClick={() => setLayer('create')}
              a11yTitle='New Account Key'
              id={IDUtil.getId('ListViewToolbarAddButton')}
              key='newServiceBtn'
            />]),
        ]}
      />
      <SearchTextContextProvider searchText={searchText}>
        <EncryptionKeyTable
          keys={filteredKeys}
          loading={keysFetching}
          onEditAccountKey={onEditAccountKey}
          onDeleteAccountKey={onDeleteAccountKey}
          onActivateAccountKey={onActivateAccountKey}

        />
      </SearchTextContextProvider>
      <Box direction='row' border='top' gap='small' pad={{ horizontal: 'small', vertical: 'small' }} flex={false}>
        <Button
          label='Close'
          type='button'
          secondary={true}
          id={IDUtil.getId('EditorViewToolbarCancelButton')}
          onClick={() => navigate('/customers')}
        />
      </Box>
      {layer === 'create' && (
        <EncryptionKeyCreateDialog
          accountId={customerId}
          onClose={() => {
            setLayer(undefined);
            setTimeout(() => {
              refetchKeys();
            }, 250);
          }}
        />
      )}
      {layer === 'deleteConfirm' && selectedAccountKey && (
        <EncryptionKeyDeleteDialog
          accountKey={selectedAccountKey}
          onClose={() => {
            setLayer(undefined);
            setSelectedAccountKey(undefined);
          }}
        />
      )}
      {layer === 'edit' && selectedAccountKey && (
        <EncryptionKeyEditDialog
          accountId={customerId}
          keyId={selectedAccountKey.keyId}
          name={selectedAccountKey.name}
          active={selectedAccountKey.active}
          onClose={() => {
            setLayer(undefined);
            setSelectedAccountKey(undefined);
          }}
        />
      )}

    </Main>
  );
};

export default EncryptionKeyListPage;
