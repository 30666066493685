// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useContext, useMemo, useState } from 'react';
import {
  Anchor, Box, Tip,
} from 'grommet';
import { StatusWarningSmall } from 'grommet-icons';
import moment from 'moment/moment';
import Highlight from 'react-highlighter';
import { ApiContext } from '../../../AppContext';
import { useDeletePendingFileMutation } from '../../../core';
import GLBMNameValueList from '../../shared/component/GLBMNameValueList';
import ConfirmationDialog from '../../shared/dialogs/ConfirmationDialog';
import IDUtil from '../../shared/util/IDUtil';
import ServiceTypeStore from '../../stores/ServiceTypeStore';

// produce a list of rowProps for each row in the table for rows that are older than 3 hours:
export const useWarningFiles = pendingFiles => useMemo(() => pendingFiles?.reduce((acc, file) => {
  if (moment.utc().diff(moment.utc(file.lastModified), 'hours') > 3) {
    acc.push(file.fileName);
  }
  return acc;
}, []) || [], [pendingFiles]);

export const useColumns = (searchText, warningFiles) => {
  const apiContext = useContext(ApiContext);
  const [dataToDelete, setDataToDelete] = useState();

  const { mutate: deletePendingFile } = useDeletePendingFileMutation({
    onSuccess: () => {
      setDataToDelete(undefined);
    },
  });

  const columns = useMemo(
    () => [{
      property: 'fileName',
      header: 'Name',
      render: ({ fileName, lastModified }) => (
        <Box direction='row' align='center'>
          <Box width={{ min: '24px' }}>
            {warningFiles?.includes(fileName) && (
            <Tip
              content={`File has not been processed for ${moment.utc().diff(moment.utc(lastModified), 'hours')} hours, check sync jobs for more information on why.`}
            >
              <StatusWarningSmall color='status-warning' />
            </Tip>
            )}
          </Box>
          <Highlight search={searchText}>{fileName}</Highlight>
        </Box>
      ),
      primary: true,
    }, {
      property: 'serviceType',
      header: 'Service Type',
      render: ({ serviceType }) => (
        <Highlight search={searchText}>
          {ServiceTypeStore.getService(serviceType)?.label || serviceType || ' - '}
        </Highlight>
      ),
    }, {
      property: 'accountId',
      header: 'Billing Account',
      render: ({ accountId }) => (
        <Highlight search={searchText}>{accountId}</Highlight>
      ),
    }, {
      property: 'sizeInBytes',
      header: 'Size In Bytes',
      align: 'end',
      render: ({ sizeInBytes }) => (
        <Highlight search={searchText}>{sizeInBytes.toLocaleString()}</Highlight>
      ),
    }, {
      property: 'lastModified',
      header: 'Last Modified',
      dataCallback: ({ lastModified }) => moment.utc(lastModified)
        .format('x'),
      render: ({ lastModified }) => (
        <Highlight search={searchText}>
          {moment.utc(lastModified)
            .format('lll')}
        </Highlight>
      ),
    }, {
      property: 'actions',
      header: 'Actions',
      render: datum => (
        <Box direction='row' gap='small'>
          <Anchor
            key='upload'
            id={IDUtil.getId('PendingFilesDownload', datum.fileName)}
            onClick={() => {
              const path = apiContext('administration.pendingFiles.download.path', { filename: datum.fileName });
              const windowTab = window.open(path, '_blank');
              if (windowTab) {
                windowTab.focus();
              }
            }}
          >
            Download
          </Anchor>
          {warningFiles?.includes(datum?.fileName) && (
            <Anchor
              key='ack'
              id={IDUtil.getId('PendingFilesDelete', datum.fileName)}
              onClick={() => {
                setDataToDelete(datum);
              }}
            >
              Delete
            </Anchor>
          )}
        </Box>
      ),
      sortable: false,
      size: '250px',
    }
    ],
    [searchText, warningFiles]
  );

  let layers;
  if (dataToDelete) {
    layers = (
      <ConfirmationDialog
        data={dataToDelete}
        title='Are you sure?'
        submitLabel='Yes, delete the Pending File'
        cancelLabel='Cancel'
        text='Deleting will permanently erase the pending file. Please ensure this is what you are intending before choosing "Yes" below.'
        onClose={() => setDataToDelete(undefined)}
        onChange={() => deletePendingFile(dataToDelete?.fileName)}
        details={(
          <Box margin={{ top: 'small' }}>
            <GLBMNameValueList
              title='Selected pending file'
              data={[
                { label: 'Name', value: dataToDelete?.fileName },
                { label: 'Service Type', value: ServiceTypeStore.getService(dataToDelete?.serviceType)?.label || dataToDelete?.serviceType || ' - ' },
                { label: 'Billing Account', value: dataToDelete?.accountId },
                { label: 'Size In Bytes', value: dataToDelete?.sizeInBytes.toLocaleString() },
                { label: 'Last Modified', value: moment.utc(dataToDelete?.lastModified).format('lll') },
              ]}
            />
          </Box>
        )}
      />
    );
  }

  return { columns, layers };
};
