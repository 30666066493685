// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  Box,
  Button, CheckBoxGroup,
  Footer,
  Text,
} from 'grommet';
import { cloneDeep } from 'lodash';
import GLBMLayer from '../shared/component/GLBMLayer';
import TypeLabel from '../shared/component/TypeLabel';
import { UserType } from '../shared/constants/UserType';
import GLBMFilterPropertyBox from '../shared/component/GLBMFilterPropertyBox';
import IDUtil from '../shared/util/IDUtil';

const UserFilter = ({ filter: initialFilter = {}, onChange = null, onClose = null }) => {
  const [filter, setFilter] = useState(cloneDeep(initialFilter));

  const _onChange = (name, event) => {
    const newFilter = { ...filter };
    if (event.value.length === 0 || event.value[event.value.length - 1] === 'all') {
      delete newFilter[name];
    } else {
      newFilter[name] = event.value.filter(el => el !== 'all');
    }
    setFilter(newFilter);
  };

  const _onSubmit = () => {
    const newFilter = { ...filter };
    onChange(newFilter);
  };

  const _renderRoleOptions = (role) => {
    const options = UserType.enumValues.map(({ enumKey, label }) => ({
      label: <TypeLabel label={label} />,
      value: enumKey,

    })).sort((a, b) => a.label.props.label.localeCompare(b.label.props.label));

    return (
      <CheckBoxGroup
        data-testid='roleCBGroup'
        labelKey='label'
        valueKey='value'
        value={role || ['all']}
        options={[{ label: <Text>All</Text>, value: 'all' }].concat(options)}
        onChange={event => _onChange('role', event)}
      />
    );
  };

  const _renderEmailOptions = (email) => {
    const options = [{
      label: <TypeLabel label='HPE Emails' />,
      value: '^[A-Za-z0-9._%+-]+@hpe\\.com$',
    }, {
      label: <TypeLabel label='Non-HPE Emails' />,
      value: '^(?![A-Za-z0-9._%+-]+@hpe\\.com$)',
    }];

    return (
      <CheckBoxGroup
        data-testid='emailCBGroup'
        labelKey='label'
        valueKey='value'
        value={email || ['all']}
        options={[{ label: <Text>All</Text>, value: 'all' }].concat(options)}
        onChange={event => _onChange('email', event)}
      />
    );
  };

  const _renderUserTypeOptions = (apiClient) => {
    const options = [{
      label: <TypeLabel label='Users' />,
      value: 'false',
    }, {
      label: <TypeLabel label='API Clients' />,
      value: 'true',
    }];

    return (
      <CheckBoxGroup
        data-testid='apiClientCBGroup'
        labelKey='label'
        valueKey='value'
        value={apiClient || ['all']}
        options={[{ label: <Text>All</Text>, value: 'all' }].concat(options)}
        onChange={event => _onChange('apiClient', event)}
      />
    );
  };

  return (
    <GLBMLayer
      position='right'
      full='vertical'
      flush={true}
      closer={false}
      a11yTitle='User Filter'
      style={{ minWidth: '400px' }}
      onEsc={onClose}
      onClickOutside={onClose}
      onClose={onClose}
      title='Filter Users'
    >
      <Box
        pad='none'
        direction='column'
        fill='vertical'
      >
        <Box flex={true}>
          <GLBMFilterPropertyBox label='Role'>
            {_renderRoleOptions(filter.role)}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Email'>
            {_renderEmailOptions(filter.email)}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='User Type'>
            {_renderUserTypeOptions(filter.apiClient)}
          </GLBMFilterPropertyBox>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Apply'
              type='button'
              primary={true}
              onClick={_onSubmit}
            />
            <Button
              label='Cancel'
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
          <Button
            label='Clear Filters'
            id={IDUtil.getId('ResetBtn')}
            onClick={() => setFilter({})}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

UserFilter.propTypes = {
  filter: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

export default UserFilter;
