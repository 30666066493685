// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Search } from 'grommet-icons';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS
import { configurableDeviceAndComponent } from 'services/Util';
import {
  Box, Button, Footer, TextInput,
} from 'grommet';
import GridUtil from '../../../../../../shared/util/GridUtil';
import GLBMHeading from '../../../../../../shared/component/GLBMHeading';
import GLBMLayer from '../../../../../../shared/component/GLBMLayer';
import ServiceTypeStore from '../../../../../../stores/ServiceTypeStore';
import { useUnconfiguredEquipmentQuery } from '../../../../../../../core';

const CustomTiersDetails = ({
  options, equipment, components, onClose, originalOptions
}) => {
  const [gridOptions, setGridOptions] = useState({});

  const [searchText, setSearchText] = useState('');

  const { customerId, serviceType } = options;
  const {
    data: unconfiguredEquipmentData,
  } = useUnconfiguredEquipmentQuery(customerId, serviceType, options, equipment, components, originalOptions);

  const buildColumns = () => {
    const serviceDef = ServiceTypeStore.getService(options.serviceType);
    if (serviceDef) {
      return GridUtil.createColumns(serviceDef, serviceDef.diagnosticDetails.meteringDistinctColumns || serviceDef.diagnosticDetails.equipmentDistinctColumns);
    }
    return [];
  };

  const buildRowData = (results) => {
    const rowData = [];
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const serviceType = ServiceTypeStore.getService(options.serviceType);
    if (configurableDeviceAndComponent(serviceType)) {
      if (results && results.unconfiguredDetails && results.unconfiguredDetails.equipment) {
        for (let i = 0; i < results.unconfiguredDetails.equipment.length; i += 1) {
          const array = results.unconfiguredDetails.equipment[i];
          if (array && array.details && array.details.drives) {
            for (let j = 0; j < array.details.drives.length; j += 1) {
              const drive = array.details.drives[j];
              rowData.push({
                name: array.name,
                deviceId: array.deviceId,
                ...drive
              });
            }
          }
        }
      }
    } else if (results && results.unconfiguredDetails && results.unconfiguredDetails.equipment) {
      for (let i = 0; i < results.unconfiguredDetails.equipment.length; i += 1) {
        const resource = results.unconfiguredDetails.equipment[i];
        rowData.push(Object.assign(resource, resource.details));
      }
    }
    return rowData;
  };

  const columnDefs = useMemo(() => {
    if (unconfiguredEquipmentData) {
      return buildColumns(unconfiguredEquipmentData);
    }
    return [];
  }, [unconfiguredEquipmentData]);

  const rowData = useMemo(() => {
    if (unconfiguredEquipmentData) {
      return buildRowData(unconfiguredEquipmentData);
    }
    return [];
  }, [unconfiguredEquipmentData]);

  const onSearchChange = (event) => {
    const search = event.target.value;
    setSearchText(search);
  };

  return (
    <GLBMLayer
      onEsc={onClose}
      onClickOutside={onClose}
    >
      <Box pad='small' size='full' gap='small'>
        <GLBMHeading
          title='Resources with no Tier'
          search={(
            <TextInput
              placeholder='Search'
              icon={<Search />}
              value={searchText}
              onChange={onSearchChange}
            />
)}
        />
        <div
          className='ag-theme-balham'
          style={{ height: '500px', width: '1200px', maxWidth: 'calc(100vw - 128px)' }}
        >
          <AgGridReact
            toolPanelSuppressSideButtons={true}
            enableColResize={true}
            enableSorting={true}
            animateRows={true}
            gridOptions={gridOptions}
            onGridReady={params => setGridOptions(params)}
            columnDefs={columnDefs}
            quickFilterText={searchText}
            rowData={rowData}
          />
        </div>
      </Box>
      <Footer border='top' pad='small'>
        <Button
          label='Close'
          type='button'
          secondary={true}
          onClick={onClose}
        />
      </Footer>
    </GLBMLayer>
  );
};

CustomTiersDetails.propTypes = {
  onClose: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  originalOptions: PropTypes.object.isRequired,
  equipment: PropTypes.array.isRequired,
  components: PropTypes.array.isRequired,

};

export default CustomTiersDetails;
