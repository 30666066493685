// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useState } from 'react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import {
  Box, Button, Header, Heading, Notification,
} from 'grommet';
import { FormNextLink } from 'grommet-icons';
import MarketingFooter from '../headers/MarketingFooter';
import MarketingHeader from '../headers/MarketingHeader';
import IDUtil from '../shared/util/IDUtil';
import AuthActions from '../actions/AuthActions';
import AppDispatcher from '../dispatcher/AppDispatcher';
import UserConstants from '../constants/UserConstants';
import { getOktaAuthClient } from './config';

const getParamValue = param => sessionStorage.getItem(param);

const LoginCallbackPage = () => {
  const [loggingOut, setLoggingOut] = useState(false);
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const [error, setError] = useState('');

  useEffect(() => {
    if (authState?.isAuthenticated) {
      // retrieve access + id tokens:
      const { accessToken, idToken } = { ...authState };

      // store them in the auth client:
      try {
        const { appEnv } = { ...window.config };
        const authClient = getOktaAuthClient(appEnv);
        if (accessToken && idToken) {
          authClient.tokenManager.add('idToken', idToken);
          authClient.tokenManager.add('accessToken', accessToken);
        }
      } catch (err) {
        console.error(err);
      }

      sessionStorage.setItem('token', accessToken.accessToken);
      AuthActions.loginOkta(accessToken.accessToken).then((res) => {
        if (typeof Storage !== 'undefined') {
          sessionStorage.setItem('lastTokenRefresh', new Date().toISOString());
          const userCopy = JSON.parse(JSON.stringify(res));
          sessionStorage.setItem('user', JSON.stringify(userCopy));
        }
        AppDispatcher.dispatch({
          actionType: UserConstants.LOGIN_SUCCESS,
          user: res,
          source: 'sso',
        });

        // after okta login, check if we should reload into preview link:
        const previewUrl = getParamValue('previews');
        if (previewUrl) {
          navigate(toRelativeUrl(`/?previews=${previewUrl}`, window.location.origin));
          window.location.reload();
        } else {
          navigate('/', { replace: true });
        }
      })
        .catch((err) => {
          setError(err?.body?.message || `There was an error. Please try again. ${JSON.stringify(err)}`);
        });
    }
  }, [authState, navigate]);

  // call oktaAuth to properly sign out using okta
  const signOut = async () => {
    const idToken = await oktaAuth.tokenManager.get('idToken');
    const accessToken = await oktaAuth.tokenManager.get('accessToken');
    await AuthActions.logout();
    await oktaAuth.signOut({ idToken, accessToken });
  };

  const renderLoggingInComponent = () => (
    <Box flex={false} fill={true} data-testid='login-wrapper'>
      {authState?.accessToken && (
        <Box align='end' pad={{ horizontal: 'medium' }} margin={{ vertical: 'large' }}>
          <Button
            data-testid='login-button'
            primary={true}
            fill='horizontal'
            busy={true}
            label='...Logging in...'
          />
        </Box>
      )}
    </Box>
  );

  const renderErrorComponent = () => (
    <Box pad={{ vertical: 'medium' }} data-testid='error-wrapper'>
      <Notification
        status='critical'
        title='Login Error'
        message={error}
      />
      <Box pad={{ vertical: 'medium' }}>
        <Button
          secondary={true}
          fill={true}
          onClick={() => {
            setLoggingOut(true);
            signOut();
          }}
          icon={<FormNextLink size='small' />}
          label='Return to Login'
          busy={loggingOut}
        />
      </Box>
    </Box>
  );

  return (
    <Box
      fill={true}
      direction='column'
    >
      <MarketingHeader />
      <Box fill={true} background='#425563'>
        <Box fill={true} direction='row' justify='center'>
          <Box fill='vertical' justify='center'>
            <Box
              overflow='auto'
              direction='column'
            >
              <Box
                style={{ overflow: 'hidden' }}
                background='white'
                elevation='xlarge'
                round={true}
              >
                <Box id={IDUtil.getId('LoginFormContainer')}>
                  <Box pad='large'>
                    <Box
                      pad='medium'
                      id='loginForm'
                      className={`${IDUtil.getClass('LoginFormClass')}`}
                    >
                      <Header flex={false} direction='column'>
                        <Box style={{ marginBottom: '0px' }}>
                          <img src='/img/hpe_pri_grn_pos_rgb.svg' height='177' alt='HPE Logo' />
                        </Box>
                        <Heading
                          style={{ fontSize: '34px' }}
                          strong={true}
                          uppercase={false}
                          truncate={false}
                        >
                          HPE GreenLake Billing Manager
                        </Heading>
                      </Header>
                    </Box>
                    <Box align='end' pad={{ horizontal: 'medium' }}>
                      {!error && renderLoggingInComponent()}
                      {error && renderErrorComponent()}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <MarketingFooter />
    </Box>
  );
};

export default LoginCallbackPage;
