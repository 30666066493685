// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'grommet';
import InputProperties from '../InputProperties';
import SelectMigrationOption from './SelectMigrationOption';

const MigrationOptions = ({
  data,
  values,
  onChange,
}) => {
  if (data?.options) {
    return (
      <SelectMigrationOption
        options={data?.options}
        values={values}
        onSelect={onChange}
      />
    );
  }

  if (data?.inputs) {
    return (
      <InputProperties
        onChange={onChange}
        inputs={data.inputs}
        values={values}
      />
    );
  }

  return <Text>No parameters</Text>;
};

MigrationOptions.propTypes = {
  data: PropTypes.object.isRequired,
  values: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

MigrationOptions.defaultProps = {
  values: undefined,
};

export default MigrationOptions;
