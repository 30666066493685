// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box, Main, Notification, Tab, Tabs,
} from 'grommet';
import CustomerSelector from '../shared/component/CustomerSelector';
import {
  pagePermissions,
} from '../shared/constants/Permissions';
import { usePermissionChecker, useStateWithSessionStorage } from '../shared/hooks';
import GLBMHeading from '../shared/component/GLBMHeading';
import { useSelectedCustomerId, useSelectedCustomerInfo } from '../contexts';
import CapacityPlanning from './capacityPlanning/CapacityPlanning';
import InvoicePage from './invoice/InvoicePage';
import SortOrderPage from './invoice/SortOrderPage';

const AnalyticsPage = () => {
  const params = useParams();

  const [selectedTabIndex, setSelectedTabIndex] = useState(params.tab === 'capacity' ? 1 : 0);
  const [showSortOrder, setShowSortOrder] = useState(false);
  const [response, setResponse] = useState(undefined);

  const [filters, setFilters] = useStateWithSessionStorage('analyticsFilters', {
    customerId: params.customerId,
    selectedService: params.serviceType,
  });

  const [_, setSelectedCustomerId] = useSelectedCustomerId();
  const selectedCustomer = useSelectedCustomerInfo();

  const _onSelectCustomer = (id) => {
    setFilters({
      customerId: id,
      selectedService: undefined,
    });
    setSelectedCustomerId(id);
  };

  const _capacityPlanningTab = customer => (
    <Tab title='Capacity Planning' key='analytics_capacityPlanning'>
      {customer && (
        <CapacityPlanning
          selectedCustomer={customer}
          selectedService={filters.selectedService}
        />
      )}
    </Tab>
  );

  const _toggleShowOrder = () => {
    setShowSortOrder(!showSortOrder);
  };

  const _invoiceTab = customer => (
    <Tab title='Monthly Charges' key='analytics_invoice'>
      {customer && <InvoicePage selectedCustomer={customer} toggleSetOrder={_toggleShowOrder} />}
    </Tab>
  );

  const { hasPermissions } = usePermissionChecker();

  const tabs = useMemo(() => {
    const myTabs = [];
    if (selectedCustomer) {
      if (hasPermissions(pagePermissions.analytics.tabs.invoice)) {
        myTabs.push(_invoiceTab(selectedCustomer));
      }
      if (hasPermissions(pagePermissions.analytics.tabs.capacity)) {
        myTabs.push(_capacityPlanningTab(selectedCustomer));
      }
    }
    return myTabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer, hasPermissions]);

  const _onTabChange = (index) => {
    setSelectedTabIndex(index);
  };

  const _onToastClose = () => {
    setResponse(undefined);
  };
  const _renderToast = () => {
    let message = '';

    if (response) {
      message = (
        <Notification
          toast={true}
          status={response.status}
          title={response.title}
          message={response.message}
          onClose={() => _onToastClose()}
        />
      );
    }
    return message;
  };

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title='Analytics'
        search={(
          <Box flex={false} width='500px'>
            <CustomerSelector
              onCustomerSelected={option => _onSelectCustomer(option)}
              excludedStates={['REQUEST']}
              excludedServiceStates={['NEW']}
              excludeNoServices={true}
              persistSelection={true}
            />
          </Box>
        )}
      />
      <Box border='top' flex={true}>
        {selectedCustomer && (
          <Box flex={true} className='tabWrapper'>
            {showSortOrder && <SortOrderPage selectedCustomer={selectedCustomer.id} onClose={() => _toggleShowOrder()} />}
            {!showSortOrder && (
              <Tabs activeIndex={selectedTabIndex} onActive={index => _onTabChange(index)}>
                {tabs}
              </Tabs>
            )}
          </Box>
        )}
      </Box>
      {_renderToast()}
    </Main>
  );
};

export default AnalyticsPage;
