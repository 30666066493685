// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo } from 'react';
import moment from 'moment';
import { Box } from 'grommet';
import { StatusIcon } from '../../../../../shared/component/StatusIcon';
import GLBMTooltip from '../../../../../shared/component/GLBMTooltip';
import { isMeterDiscontinued } from '../util';

export const getLocationRateStatus = (r, require, selected) => {
  let status = require ? 'critical' : 'warning';
  if (r && (r.commitments?.length ?? 0) > 0) {
    status = 'ok';
  }
  return (<StatusIcon value={status} size='small' useColor={!selected} />);
};

export const getStatus = (r, selected) => {
  let status = 'ok';
  if (!r) {
    status = 'unknown';
  } else if (!r.tierType) {
    status = 'critical';
  } else if (r.tierType === 'TIERED') {
    let errors = 0;
    r?.rateRevisions?.forEach((revision) => {
      const { effectiveDate: rateEffectiveDate, tiers } = revision;
      const foundCapacity = r?.capacityRevisions?.find((cap, index, allCapacities) => {
        const currentEffectiveDate = cap?.effectiveDate;
        const nextEffectiveDate = allCapacities[index + 1]?.effectiveDate;
        return moment(currentEffectiveDate, 'YYYY-MM-DD').isSameOrBefore(moment(rateEffectiveDate, 'YYYY-MM-DD')) && (!nextEffectiveDate || moment(nextEffectiveDate, 'YYYY-MM-DD').isAfter(moment(rateEffectiveDate, 'YYYY-MM-DD')));
      });
      const limit = foundCapacity?.committedCapacityOverrideEnabled ? foundCapacity?.committedCapacityOverride : foundCapacity?.committedCapacity;
      const formattedLimit = limit && (typeof limit === 'string') ? +limit : limit;
      const isError = Object.keys(tiers).some(tier => formattedLimit && (+tier >= formattedLimit));
      if (isError) {
        errors += 1;
      }
    });
    if (errors > 0) {
      return (
        <GLBMTooltip content={`${errors} rate revision${errors > 1 ? 's' : ''} have a pricing band that has a start range that exceeds its corresponding Reserved Capacity.`}>
          <Box><StatusIcon value='critical' size='small' /></Box>
        </GLBMTooltip>
      );
    }
  } else if (r.totalCost === 0) {
    status = 'warning';
  } else if (isMeterDiscontinued(r)) {
    status = 'disabled';
  }
  return (<StatusIcon value={status} size='small' useColor={!selected} />);
};

export const getTotalMeterCost = (r) => {
  let totalRate = 0;
  const revisions = r.rateRevisions;
  // let's see how many revisions we have:
  if (Object.keys(revisions).length === 0) {
    // no revisions... definitely no cost...
  } else {
    Object.keys(revisions).forEach((revisionValue) => {
      const revision = revisions[revisionValue];
      // let's see how many tiers this revision has:
      if (!revision.tiers || Object.keys(revision.tiers).length === 0) {
        // no tiers... definitely no cost...
      } else {
        // now calculate total cost by adding up all the rates for each tier for each revision:
        Object.keys(revision.tiers).forEach((tierValue) => {
          const tier = revision.tiers[tierValue];
          totalRate += tier.rate;
        });
      }
    });
  }

  // if we get here for a meter and have a totalRate of zero - then no cost exists at all across revisions:
  return totalRate;
};

/**
 * this function will return > 0 if the user has configured at least one of the meter revisions
 * with a pricing method (direct or markup)... then the meter will be green vs. yellow in meter list.
 * @param r
 * @returns {number}
 * @private
 */
const getTotalPartnerCost = (r) => {
  let totalRate = 0;
  const revisions = r.rateRevisions;
  // let's see how many revisions we have:
  if (Object.keys(revisions).length === 0) {
    // no revisions... definitely no cost...
  } else {
    Object.keys(revisions).forEach((revisionValue) => {
      const revision = revisions[revisionValue];
      const hasPricingMethod = revision && Object.prototype.hasOwnProperty.call(revision, 'explicitDownstreamRate');
      if (hasPricingMethod) {
        totalRate += 1;
      }
    });
  }

  // if we get here for a meter and have a totalRate of zero - then no cost exists at all across revisions:
  return totalRate;
};

export const useRateProps = ({ tenant, rates }) => {
  const getTotalCost = (tenant === 'MASTER' ? getTotalMeterCost : getTotalPartnerCost);
  return useMemo(() => (rates || []).reduce(
    (mapObj, rate) => ({
      ...mapObj,
      [rate.meterId]: {
        description: rate.description,
        totalCost: getTotalCost(rate),
        tierType: rate.tierType,
        locationId: rate.locationId,
        rateRevisions: rate.rateRevisions,
        capacityRevisions: rate.capacityRevisions
      },
    }),
    {},
  ), [getTotalCost, rates]);
};

export const useLocationRateProps = ({ locationRates }) => (
  useMemo(() => (locationRates || []).reduce(
    (mapObj, r) => ({
      ...mapObj,
      [r.locationId]: r,
    }),
    {}
  ), [locationRates])
);

export const useLocationRequireCommitmentProps = ({ meters, rates, locationRates }) => useMemo(() => {
  const locationsWithCommitments = locationRates?.reduce((acc, location) => {
    if (location.commitments?.length > 0) {
      const { locationId } = location;
      if (acc.indexOf(locationId) === -1) {
        acc.push(locationId);
      }
    }
    return acc;
  }, []).sort();

  const locationsWithMeterRates = meters?.reduce((acc, meter) => {
    const { id, locationId } = meter;
    const rate = rates.find(r => r.meterId === id);
    if (rate) {
      const totalCost = getTotalMeterCost(rate);
      if (totalCost > 0) {
        if (locationId && acc.indexOf(locationId) === -1) {
          acc.push(locationId);
        }
      }
    }
    return acc;
  }, []).sort();

  // now loop on all the locations with meter rates - those that exist here
  // require that we also have commitment at the location (the parent):
  return (locationsWithMeterRates || []).reduce(
    (mapObj, locationId) => ({
      ...mapObj,
      [locationId]: locationsWithCommitments?.indexOf(locationId) === -1,
    }),
    {}
  );
}, [rates, locationRates]);
