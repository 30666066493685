// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, {
  useCallback, useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { produce } from 'immer';

import {
  Button,
  Main,
  Notification,
} from 'grommet';
import { Refresh } from 'grommet-icons';
import IDUtil from '../shared/util/IDUtil';
import { useUsersQuery } from '../../core';
import GLBMDataSummary from '../shared/component/GLBMDataSummary';
import GLBMSearch from '../shared/component/GLBMSearch';
import useRememberedState from '../shared/hooks/useRememberedState';
import FilterControl from '../shared/component/FilterControl';
import GLBMHeading from '../shared/component/GLBMHeading';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import Toast from '../shared/component/Toast';
import ColumnSelector, {
  useColumnSelectorProps,
} from '../shared/component/ColumnSelector';
import { SearchTextContextProvider } from '../shared/component/HighlightUsingContext';
import UserFilter from './UserFilter';
import {
  filterUsers,
  useAvailableColumns,
} from './listUtils';
import UserHistoryDetails from './UserHistoryDetails';
import EditApprovalPermission from './EditApprovalPermissions';

const UsersListPage = () => {
  const [storedFilters, setStoredFilters] = useRememberedState('user-list-filter', {
    list: {
      sort: { property: 'name', direction: 'asc', external: true },
    },
    panel: {},
  });
  const [filters, setFilters] = useState(storedFilters);
  const [layer, setLayer] = useState('');
  const [saveResponse, setSaveResponse] = useState(undefined);
  const [selectedUserId, setSelectedUserId] = useState(undefined);

  const {
    data: users,
    isFetching: isFetchingUsers,
    refetch: refreshUsers,
  } = useUsersQuery();

  const availableColumns = useAvailableColumns(setLayer, setSelectedUserId);
  const [selectedColumns, columnSelectorProps] = useColumnSelectorProps(
    availableColumns,
    'users-list-columns',
    ['name', 'email', 'role', 'id', 'actions'],
    ['name', 'email', 'role', 'actions'],
  );

  const { searchText, sort } = filters?.list || {};
  const filteredUsers = useMemo(() => filterUsers(searchText, filters, users, selectedColumns), [searchText, filters, users, selectedColumns]);
  const onSearchChange = useCallback((value) => {
    const search = value;
    const newList = { ...filters?.list };
    newList.searchText = search;
    const newFilters = { ...filters, list: newList };
    setFilters(newFilters);
    setStoredFilters(newFilters);
  }, [filters]);

  const onSortColumn = useCallback((newSort) => {
    const updatedFilters = produce(filters, draft => ({
      ...draft,
      list: {
        ...draft.list,
        sort: newSort,
      },
    }));

    setFilters(updatedFilters);
    setStoredFilters(updatedFilters);
  }, [filters]);

  const onFilterChange = useCallback((filterPanel) => {
    const updatedFilters = produce(filters, draft => ({ ...draft, panel: filterPanel }));
    setFilters(updatedFilters);
    setStoredFilters(updatedFilters);
    setLayer(undefined);
  }, [filters]);

  const selectedUser = useMemo(() => users?.filter(u => u.id === selectedUserId)[0], [users, selectedUserId]);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title='User Management'
        search={[
          <GLBMSearch
            key='searchText'
            data-testid='search-input'
            value={filters?.list?.searchText}
            onChange={onSearchChange}
          />,
          <ColumnSelector key='columnSelector' {...columnSelectorProps} />,
          <FilterControl
            key='filterCntrl'
            filters={filters?.panel}
            onFilter={() => setLayer('filterActive')}
            onClear={() => onFilterChange({ })}
          />,
        ]}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            onClick={() => {
              refreshUsers();
            }}
            a11yTitle='Refresh User List'
            id={IDUtil.getId('ListViewToolbarRefreshButton')}
            key='refreshBtn'
            label='Refresh'
            busy={isFetchingUsers}
          />,
        ]}
      />
      <GLBMDataSummary total={users ? users.length : 0} filtered={filteredUsers ? filteredUsers.length : 0} />
      <SearchTextContextProvider searchText={searchText}>
        <GLBMDataTable
          searchText={searchText}
          data={filteredUsers || []}
          columns={selectedColumns}
          loading={isFetchingUsers}
          total={filteredUsers?.length}
          sort={sort}
          onSort={onSortColumn}
        />
      </SearchTextContextProvider>
      {layer === 'filterActive' && (
        <UserFilter
          onClose={() => setLayer(undefined)}
          onChange={onFilterChange}
          filter={filters?.panel}
        />
      )}
      {layer === 'editApproversPermission' && (
        <EditApprovalPermission
          onClose={() => {
            setLayer(undefined);
            setSelectedUserId(undefined);
          }}
          user={selectedUser}
          refreshUsers={() => {
            refreshUsers();
          }}
        />
      )}
      {layer === 'historyActive' && (
        <UserHistoryDetails
          onClose={() => {
            setLayer(undefined);
            setSelectedUserId(undefined);
          }}
          user={selectedUser}
        />
      )}
      {!!saveResponse && (
        <Toast
          open={saveResponse}
          status={saveResponse.status}
          onClose={() => setSaveResponse(undefined)}
        >
          {saveResponse.text}
        </Toast>
      )}
      {users?.error && (
        <Notification
          toast={true}
          status='critical'
          title='Fetch Users Error'
          message={users?.error.message}
        />
      )}
    </Main>
  );
};

UsersListPage.contextTypes = {
  router: PropTypes.object,
};

export default UsersListPage;
