// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Select, Text } from 'grommet';
import { useGlpBindingsQuery } from '../../../core';

const GLPBindingSelector = ({
  onBindingSelected = undefined,
  ...rest
}) => {
  const [selected, setSelected] = useState(undefined);
  const [searchText, setSearchText] = useState(undefined);

  // load the GLP bindings
  const {
    isLoading: bindingsIsLoading,
    // isError: bindingsIsError,
    data: bindingsData,
  } = useGlpBindingsQuery({
    retry: false,
  });

  const bindingOptions = useMemo(
    () => bindingsData?.filter(binding => (searchText ? (searchText.test(binding.workspaceName) || searchText.test(binding.platformCustomerId)) : true))
      .map(binding => ({
        label: binding.workspaceName,
        value: binding.caTenantId,
        platformCustomerId: binding.platformCustomerId
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    [searchText, bindingsData]
  );

  useEffect(() => {
    if (onBindingSelected && selected && bindingsData) {
      // find the binding with the selected id
      const selectedBinding = bindingsData.find(binding => binding.caTenantId === selected?.value && selected?.platformCustomerId === binding.platformCustomerId);
      onBindingSelected(selectedBinding);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, bindingsData]);

  return (
    <Select
      id='glpBindingSelector'
      name='glpBindingSelector'
      labelKey='label'
      options={bindingsIsLoading ? [] : bindingOptions}
      value={selected}
      onChange={({ option }) => {
        setSelected(option);
      }}
      placeholder='Select a GLP Workspace'
      onBlur={() => setSearchText(undefined)}
      onSearch={(text) => {
        if (!text) {
          setSearchText(undefined);
        } else {
          setSearchText(new RegExp(text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'), 'i'));
        }
      }}
      {...rest}
    >
      {option => (
        <Box
          background={selected?.value === option.value && selected?.platformCustomerId === option.platformCustomerId ? '#01A982' : undefined}
          direction='column'
          pad='small'
        >
          <Text size='medium' weight={selected?.value === option.value && selected?.platformCustomerId === option.platformCustomerId ? 'bold' : undefined}>{option.label}</Text>
          <Text size='xsmall' weight={selected?.value === option.value && selected?.platformCustomerId === option.platformCustomerId ? 'bold' : undefined}>{option.platformCustomerId}</Text>
        </Box>
      )}
    </Select>
  );
};

GLPBindingSelector.propTypes = {
  onBindingSelected: PropTypes.func,
};

export default GLPBindingSelector;
