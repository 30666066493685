// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, {
  useContext, useMemo, useState,
} from 'react';
import Highlight from 'react-highlighter';
import {
  Box,
  Button,
  Main, Menu, Notification, Text,
} from 'grommet';
import {
  Link, More, Refresh,
} from 'grommet-icons';
import moment from 'moment';
import ConfirmationDialog from '../shared/dialogs/ConfirmationDialog';
import IDUtil from '../shared/util/IDUtil';
import { useUsageFilesQueries } from '../../core';
import CustomerSelector from '../shared/component/CustomerSelector';
import GLBMDataSummary from '../shared/component/GLBMDataSummary';
import GLBMSearch from '../shared/component/GLBMSearch';
import GLBMTooltip from '../shared/component/GLBMTooltip';
import FilterControl from '../shared/component/FilterControl';
import GLBMDataTable from '../shared/component/GLBMDataTable';
import GLBMHeading from '../shared/component/GLBMHeading';
import { ApiContext } from '../../AppContext';
import { useSelectedCustomerId } from '../contexts';
import { useStateWithSessionStorage } from '../shared/hooks';
import UsageLoadingLayer from './UsageLoadingLayer';
import UsageFileFilter from './UsageFileFilter';
import UsageUpload from './UsageUpload';

const UsageFilePage = () => {
  const contextFn = useContext(ApiContext);
  const [selectedCustomerId, setSelectedCustomerId] = useSelectedCustomerId();
  const [filters, setFilters] = useStateWithSessionStorage('usageFileFilters', {
    customerId: selectedCustomerId,
    list: { searchText: '', sort: { property: 'fileName', direction: 'asc', external: true } },
    panel: {
      from: moment.utc().subtract(1, 'day').format('YYYY-MM-DD'),
      to: moment.utc().format('YYYY-MM-DD'),
      stage: ['raw'],
    },
  });
  const [response, setResponse] = useState(undefined);
  const [filterActive, setFilterActive] = useState(false);
  const [confirmDownload, setConfirmDownload] = useState(false);
  const [showUpload, setShowUpload] = useState(false);

  const {
    data: fileList,
    refetch: refreshUsageFiles,
    isFetching: isLoadingFiles,
    total: totalUsageFiles,
    loaded: loadedUsageFiles,
    cancel: cancelUsageLoading,
  } = useUsageFilesQueries(filters?.customerId, filters?.panel, {
    enabled: !!filters?.customerId,
  });
  const filteredFiles = useMemo(() => {
    const { searchText } = filters.list;
    if (searchText) {
      return fileList?.filter(x => (!searchText) || (x.fileName.toLowerCase().indexOf(searchText.toLowerCase()) >= 0));
    }
    return fileList;
  }, [fileList, filters.list.searchText]);

  const _onFilterActivate = () => {
    setFilterActive(true);
  };

  const _onFilterDeactivate = () => {
    setFilterActive(false);
  };

  const _onSelectCustomer = (option) => {
    setFilters({ ...filters, customerId: option });
    setSelectedCustomerId(option);
  };

  const _onUpdateFilter = (filterPanel) => {
    setFilters({ ...filters, panel: filterPanel });
    setFilterActive(false);
  };

  const _onToastClose = () => {
    setResponse(undefined);
  };

  const getUsageFilesList = () => {
    setResponse(undefined);
    refreshUsageFiles();
  };

  const _downloadAllFiles = () => {
    const filterOptions = { ...filters };
    if (filters.list.searchText) {
      filterOptions.filter = filters.list.searchText.toLowerCase();
    }
    setConfirmDownload(false);
    const whiteList = ['from', 'to', 'stage', 'source', 'service', 'filter', 'rawForm'];
    const path = contextFn('usageFiles.downloadFiles.path', {
      customerId: filters?.customerId,
    }, filters, whiteList);
    window.open(path, '_blank');
  };

  const _onDownloadAllFiles = (showConfirmDownload, rawForm = true) => {
    setConfirmDownload(showConfirmDownload);
    setFilters({ ...filters, rawForm });
  };

  const downloadOneFile = (fileName, rawForm) => {
    const path = contextFn('usageFiles.downloadFile.path', {
      customerId: filters?.customerId,
      fileName,
      rawForm,
    });
    window.open(path, '_blank');
  };

  const onSearchChange = (event) => {
    const newList = { ...filters.list };
    newList.searchText = event;
    const newFilters = { ...filters, list: newList };

    setFilters(newFilters);
  };

  const { sort } = filters.list;

  const sortData = (data) => {
    let property;
    switch (sort.index) {
      case 0:
        property = 'fileName';
        break;
      case 1:
        property = 'estimated';
        break;
      case 2:
        property = 'type';
        break;
      case 3:
        property = 'date';
        break;
      case 4:
        property = 'modified';
        break;
      case 5:
        property = 'size';
        break;
      default:
        return data;
    }
    // eslint-disable-next-line no-nested-ternary
    const sortedData = data?.sort((a, b) => (a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0));

    return (sort.ascending) ? sortedData : sortedData.reverse();
  };

  const _getActions = datum => [
    {
      onClick: () => downloadOneFile(datum.fileName, true),
      label: 'Download Original',
    },
    {
      onClick: () => downloadOneFile(datum.fileName, false),
      label: 'Download Readable',
    },
  ];

  const _renderToast = () => {
    let message = '';
    if (response) {
      message = (
        <Notification
          toast={true}
          status={response.status}
          message={response.message}
          title={response.title}
          onClose={_onToastClose}
        />
      );
    }
    return message;
  };

  const _getColumns = searchText => [
    {
      property: 'fileName',
      header: 'Name',
      primary: true,
      size: 'large',
      render: ({ fileName, estimated, estimatedBy }) => (
        <Box direction='row' gap='small'>
          <Highlight search={searchText}>{fileName}</Highlight>
          {estimated && (
            <GLBMTooltip content={`Estimated by: ${estimatedBy}`}>
              <Box
                style={{ width: '18px' }}
              >
                { }
                <Link
                  size='xsmall'
                />
              </Box>
            </GLBMTooltip>
          )}
        </Box>
      ),
    },
    { property: 'estimated', header: 'Content', render: ({ estimated }) => (<Highlight search={searchText}>{estimated ? 'Estimated' : 'Actual'}</Highlight>) },
    { property: 'type', header: 'Format', render: ({ type }) => (<Highlight search={searchText}>{type}</Highlight>) },
    {
      property: 'date',
      header: 'Usage Date',
      size: '120px',
      dataCallback: ({ date }) => moment.utc(date).format('x'),
      render: ({ date }) => (<Highlight search={searchText}>{moment.utc(date).format('ll')}</Highlight>),
    },
    {
      property: 'modified',
      header: 'Last Modified',
      size: '160px',
      dataCallback: ({ modified }) => moment.utc(modified).format('x'),
      render: ({ modified }) => (<Text><Highlight search={searchText}>{moment.utc(modified).format('lll')}</Highlight></Text>),
    }, {
      property: 'size',
      header: 'Size',
      align: 'end',
      render: ({ size }) => (<Text><Highlight search={searchText}>{size.toLocaleString()}</Highlight></Text>),
    },
    {
      property: 'actions',
      header: 'Actions',
      size: '96px',
      align: 'start',
      render: datum => (
        <Menu
          icon={<More />}
          items={_getActions(datum)}
        />
      ),
      sortable: false,
    },
  ];

  const _onSortColumn = (sorting) => {
    setFilters({ ...filters, list: { ...filters.list, sort: sorting } });
  };

  const sortedData = sortData(filteredFiles);

  return (
    <Main direction='column' fill='vertical' overflow='hidden'>
      <GLBMHeading
        title='Usage Files'
        search={[
          <CustomerSelector
            key='CustomerSelector'
            initialSelection={filters?.customerId}
            onCustomerSelected={value => _onSelectCustomer(value)}
            persistSelection={true}
          />,
          <GLBMSearch
            key='searchText'
            value={filters.list.searchText}
            onChange={onSearchChange}
          />,
          <FilterControl
            key='filterCntrl'
            filters={filters?.panel}
            onFilter={_onFilterActivate}
            onClear={() => {
              const newFilterPanel = { ...filters.panel };
              delete newFilterPanel.service;
              delete newFilterPanel.source;
              delete newFilterPanel.stage;
              _onUpdateFilter(newFilterPanel);
            }}
            ignoreProps={['from', 'to']}
          />]}
        actions={[
          <Button
            kind='toolbar'
            icon={<Refresh />}
            onClick={getUsageFilesList}
            a11yTitle='Refresh Usage File List'
            id={IDUtil.getId('ListViewToolbarRefreshButton')}
            key='refreshBtn'
            label='Refresh'
            busy={isLoadingFiles}
          />,
          <Menu
            kind='toolbar'
            key='menuCntrl'
            id='actionDropDown'
            items={[
              {
                onClick: () => setShowUpload(true),
                a11yTitle: 'Upload Usage Files',
                id: IDUtil.getId('ListViewToolbarUploadButton'),
                key: 'uploadBtn',
                label: 'Upload',
              },
              {
                onClick: () => _onDownloadAllFiles(true, true),
                label: 'Download Original Files',
              },
              {
                onClick: () => _onDownloadAllFiles(true, false),
                label: 'Download Readable Files',
              },
            ]}
            label='Actions'
            reverse={false}
          />,
        ]}
      />
      <GLBMDataSummary total={fileList?.length} filtered={filteredFiles?.length} />
      <GLBMDataTable
        data={sortedData || []}
        columns={_getColumns(filters.list.searchText)}
        sort={sort}
        onSort={_onSortColumn}
      />
      {isLoadingFiles && (
        <UsageLoadingLayer
          total={totalUsageFiles}
          loaded={loadedUsageFiles}
          onCancel={cancelUsageLoading}
        />
      )}
      {filterActive
        && (
          <UsageFileFilter
            onClose={_onFilterDeactivate}
            filter={filters.panel}
            onChange={_onUpdateFilter}
          />
        )}
      {confirmDownload
        && (
          <ConfirmationDialog
            title='Download all files?'
            text={`You are about to download a file containing ${filteredFiles.length} file${filteredFiles.length > 1 ? 's' : ''} with an estimated size of ${filteredFiles.reduce((total, file) => total + file.size, 0).toLocaleString()} bytes`}
            submitLabel='Yes, download all files'
            cancelLabel='Cancel'
            onClose={() => _onDownloadAllFiles(false)}
            onChange={() => _downloadAllFiles()}
          />
        )}
      {showUpload
        && (
          <UsageUpload
            customer={filters?.customerId}
            onClose={() => setShowUpload(false)}
          />
        )}
      {_renderToast()}
    </Main>
  );
};

export default UsageFilePage;
