// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React from 'react';
import PropTypes from 'prop-types';
import { ReportingUnits } from 'services/model/ReportingUnits';
import {
  Box, FormField, RadioButton, Text, TextInput,
} from 'grommet';
import CATextInput from '../../../../../shared/form/CATextInput';
import CARadioButton from '../../../../../shared/form/CARadioButton';
import CACheckbox from '../../../../../shared/form/CACheckbox';
import ReadOnlyField from '../../../../../shared/component/ReadOnlyField';
import { useServiceEditorContext } from '../../../contexts/ServiceEditorContext';
import { _isNumber } from './utils';

const VMBillingMethodField = (props) => {
  const [serviceEditor, setServiceEditor] = useServiceEditorContext();

  const _getVM20Fields = readOnly => (
    <Box gap='small'>
      <Box flex='grow' className='tiny-inputs' direction='row' margin={{ left: 'small' }}>
        <Box style={{ minWidth: '50px' }} />
        <CATextInput
          id='input_subscription_ratio'
          label='Subscription Ratio %'
          name='input_subscription_ratio'
          value={serviceEditor.options.config.subscriptionRatio}
          onChange={(value) => {
            _onChangeUtilization('subscriptionRatio', value);
          }}
          tooltip='Subscription Ratio is the percentage of physical memory available for VMs (the rest is reserved for overhead).'
        />
      </Box>
      <Box
        flex='grow'
        className='tiny-inputs'
        direction='row'
        gap='small'
      >
        <Box style={{ minWidth: '24px' }} />
        <div>
          <CACheckbox
            label='Cap usage at Installed Capacity'
            id='check_billing_options_cap_usage'
            name='check_billing_options_cap_usage'
            disabled={readOnly}
            onChange={(event) => {
              _onChangeBillingCapUsage(event);
            }}
            checked={serviceEditor.options.config.capUsage || false}
            tooltip='When checked, memory usage exceeding the physical memory of a host due to overprovisioned VMs is not charged for.'
          />
        </div>
      </Box>
    </Box>
  );

  const _onChangeUtilization = (property, value) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));

    if (value && value.length >= 2 && value.startsWith('0')) {
      value = value.slice(1);
    }

    if (_isNumber(value)) {
      if (parseFloat(value) > 100) {
        newState.options.config[property] = 100;
      } else if (parseFloat(value) < 0) {
        newState.options.config[property] = 0;
      } else {
        newState.options.config[property] = value;
      }
    } else if (value === '') {
      newState.options.config[property] = 0;
    }
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
  };

  const _onChangeBillingMethod = (billingMethod) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config.billingMethod = billingMethod;
    switch (billingMethod) {
      case 'VM20':
      case 'VDI':
        newState.options.config.capUsage = true;
        newState.options.config.subscriptionRatio = 85;
        break;
      case 'PER_HOST_VM':
        delete newState.options.config.billingMethodUtilization;
        break;
      case 'PER_HOST_UTILIZATION':
        newState.options.config.billingMethodUtilization = 1;
        break;
      case 'PER_CORE_UTILIZATION':
        newState.options.config.billingMethodUtilization = 5;
        break;
      case 'PER_HOST_MEMORY_UTILIZATION':
        newState.options.config.billingMethodUnit = ReportingUnits.GB.enumKey;
        break;
    }
    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
  };

  const _onChangeBillingCapUsage = (event) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config.capUsage = event.target.checked;

    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
  };

  const _onChangeIncludeVmCountMeters = (event) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config.includeVmCountMeters = event.target.checked;

    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
  };

  const _onChangeBillingMemoryUtilizationUnit = (event, unit) => {
    const newState = JSON.parse(JSON.stringify(serviceEditor));
    newState.options.config.billingMethodUnit = unit;

    newState.dirtyState.options = true;
    setServiceEditor(newState);
    props.setDirty();
  };

  const _renderIncludeVmCountMeterCheckbox = readOnly => (
    <FormField label='VM Count handling'>
      <CACheckbox
        label='Include VM Count Meters (UBS Only)'
        id='check_vm_count_handling_include_vm_count_meters'
        name='check_vm_count_handling_include_vm_count_meters'
        disabled={readOnly}
        onChange={(event) => {
          _onChangeIncludeVmCountMeters(event);
        }}
        checked={serviceEditor.options.config.includeVmCountMeters || false}
        tooltip='When checked, VM Count Meters will be automatically added to this service. This should NOT be checked for most customers, it is only used to meet explicit contractual obligations for a few customers.'
      />
    </FormField>
  );

  const _getPerHostMemoryUtilizationField = () => {
    const { billingMethodUnit } = serviceEditor.options.config;
    const radioButtons = [ReportingUnits.GB, ReportingUnits.GiB, ReportingUnits.TB, ReportingUnits.TiB].map(({ enumKey }) => (
      <RadioButton
        key={enumKey}
        id={`${enumKey}-memory-utilization-units`}
        name={`${enumKey}-memory-utilization-units`}
        label={enumKey}
        checked={billingMethodUnit === enumKey}
        onChange={() => _onChangeBillingMemoryUtilizationUnit('billingMethodUnit', enumKey)}
      />
    ));

    return (
      <Box
        flex='grow'
        className='tiny-inputs'
        direction='row'
        gap='small'
      >
        <Box style={{ minWidth: '50px' }} direction='column' />
        <Box direction='column'>
          <Text weight={100} size='small'>Billing units</Text>
          {radioButtons}
        </Box>
      </Box>
    );
  };

  const _getEditableVMBillingField = () => (
    <FormField label='Set billing method to'>
      <CARadioButton
        label='VM2.0 (Compute Units)'
        id='radio_billing_options_vm20'
        name='billingOptionsVM20_'
        onChange={() => _onChangeBillingMethod('VM20')}
        checked={serviceEditor.options.config.billingMethod === 'VM20'}
      />
      {serviceEditor.options.config.billingMethod === 'VM20'
          && _getVM20Fields(false)}

      <CARadioButton
        label='Per host utilization (Host ON/OFF based on total CPU utilization per host)'
        id='radio_billing_options_per_host_utilization'
        name='radio_billing_options_per_host_utilization'
        onChange={() => _onChangeBillingMethod('PER_HOST_UTILIZATION')}
        checked={serviceEditor.options.config.billingMethod === 'PER_HOST_UTILIZATION'}
      />
      {serviceEditor.options.config.billingMethod === 'PER_HOST_UTILIZATION'
          && (
            <Box
              flex='grow'
              className='tiny-inputs'
              direction='row'
              gap='small'
              align='center'
            >
              <Box style={{ minWidth: '50px' }} />
              <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}>
                <TextInput
                  value={serviceEditor.options.config.billingMethodUtilization}
                  name='perHostUtilization'
                  onChange={(event) => {
                    _onChangeUtilization('billingMethodUtilization', event.target.value);
                  }}
                  id='perHostUtilization'
                />
              </div>
              <Text weight={100} size='small'>Utilization %</Text>
            </Box>
          )}
      <CARadioButton
        label='Per core utilization (Core ON/OFF based on core utilization)'
        id='radio_billing_options_per_core_utilization'
        name='radio_billing_options_per_core_utilization'
        onChange={() => _onChangeBillingMethod('PER_CORE_UTILIZATION')}
        checked={serviceEditor.options.config.billingMethod === 'PER_CORE_UTILIZATION'}
      />
      {serviceEditor.options.config.billingMethod === 'PER_CORE_UTILIZATION'
          && (
            <Box
              flex='grow'
              className='tiny-inputs'
              direction='row'
              gap='small'
              align='center'
            >
              <Box style={{ minWidth: '50px' }} />
              <div style={{ border: '1px solid rgba(0, 0, 0, 0.15)' }}>
                <TextInput
                  value={serviceEditor.options.config.billingMethodUtilization}
                  name='perHostUtilization'
                  onChange={(event) => {
                    _onChangeUtilization('billingMethodUtilization', event.target.value);
                  }}
                  id='perHostUtilization'
                />
              </div>
              <Text weight={100} size='small'>Utilization %</Text>
            </Box>
          )}
      <CARadioButton
        label='Per host VM (Host ON/OFF based on whether the host has active VMs)'
        id='radio_billing_options_per_host_vm'
        name='radio_billing_options_per_host_vm'
        onChange={() => _onChangeBillingMethod('PER_HOST_VM')}
        checked={serviceEditor.options.config.billingMethod === 'PER_HOST_VM'}
      />
      <CARadioButton
        label='Per host memory utilization'
        id='radio_billing_options_per_host_memory_utilization'
        name='radio_billing_options_per_host_memory_utilization'
        onChange={() => _onChangeBillingMethod('PER_HOST_MEMORY_UTILIZATION')}
        checked={serviceEditor.options.config.billingMethod === 'PER_HOST_MEMORY_UTILIZATION'}
      />
      {serviceEditor.options.config.billingMethod === 'PER_HOST_MEMORY_UTILIZATION'
          && _getPerHostMemoryUtilizationField()}
      <CARadioButton
        label='VDI GreenLake'
        id='radio_billing_options_vdi'
        name='billingOptionsVDI_'
        onChange={() => _onChangeBillingMethod('VDI')}
        checked={serviceEditor.options.config.billingMethod === 'VDI'}
      />
      {serviceEditor.options.config.billingMethod === 'VDI'
          && _getVM20Fields(false)}
    </FormField>
  );

  const _getReadOnlyVMBillingField = () => {
    const labelFromBillingMethodMap = new Map()
      .set('VM20', 'VM2.0 (Compute Units)')
      .set('PER_HOST_UTILIZATION', 'Per host utilization (Host ON/OFF based on total CPU utilization per host)')
      .set('PER_CORE_UTILIZATION', 'Per core utilization (Core ON/OFF based on core utilization)')
      .set('PER_HOST_VM', 'Per host VM (Host ON/OFF based on whether the host has active VMs)')
      .set('PER_HOST_MEMORY_UTILIZATION', 'Per host memory utilization')
      .set('VDI', 'VDI GreenLake');

    const { billingMethod } = serviceEditor.options.config;

    return (
      <ReadOnlyField
        label='Billing Method'
        value={labelFromBillingMethodMap.get(billingMethod)}
      >
        {billingMethod === 'VM20' && _getVM20Fields(true)}
        {billingMethod === 'VDI' && _getVM20Fields(true)}
      </ReadOnlyField>
    );
  };

  return (
    <>
      {props.readOnly ? _getReadOnlyVMBillingField() : _getEditableVMBillingField()}
      {_renderIncludeVmCountMeterCheckbox()}
    </>
  );
};

VMBillingMethodField.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  setDirty: PropTypes.func.isRequired,
};

export default VMBillingMethodField;
