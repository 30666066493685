// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box, RadioButton, Text, Tip,
} from 'grommet';

class CARadioButton extends Component {
  render() {
    return (
      <Box
        margin={{ bottom: '4px' }}
      >
        <RadioButton
          id={this.props.id}
          name={this.props.name}
          label={
          this.props.tooltip ? (
            <Tip
              content={(
                <Box width='medium'>
                  {this.props.tooltip}
                </Box>
                )}
              dropProps={{ align: { left: 'right' } }}
            >
              <Text style={{ borderBottom: '1px dashed rgba(0, 0, 0, 0.5)' }}>{this.props.label}</Text>
            </Tip>
          ) : <Text>{this.props.label}</Text>
}
          checked={this.props.checked}
          className={this.props.tooltip ? 'dotted-tooltip-radio' : ''}
          onChange={this.props.onChange}
        />
      </Box>
    );
  }
}

CARadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  tooltip: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CARadioButton;
