// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Box, Button, Text } from 'grommet';
import { View } from 'grommet-icons';
import IDUtil from '../../../shared/util/IDUtil';
import GLBMTooltip from '../../../shared/component/GLBMTooltip';
import { StatusIcon } from '../../../shared/component/StatusIcon';
import CatalogPlanDialog from './CatalogPlanDialog';

class CatalogPlanList extends Component {
  constructor(props) {
    super(props);

    // BIND methods:
    this._onLayerClose = this._onLayerClose.bind(this);
    this._onPlanView = this._onPlanView.bind(this);

    this.state = {
      plans: (props.plans ? props.plans : []),
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ plans: props.plans });
  }

  _onPlanView(index) {
    this.setState({ addLocation: false, viewPlanIndex: index, layer: 'viewPlan' });
  }

  _formatDate(date) {
    return new Date(date).toLocaleDateString((window.navigator.languages) ? window.navigator.languages[0] : (window.navigator.userLanguage || window.navigator.language), { timeZone: 'UTC' });
  }

  _renderPlanFields() {
    const template = this.state.plans;

    return (
      <Box>
        {
          template.length === 0 && (
            <Box pad={{ vertical: 'large' }} align='center'>
              <Text>You do not have any provisioned plans defined at the moment.</Text>
            </Box>
          )
        }
        <Box>
          {
            template.map((plan, index) => (
              <GLBMTooltip content={!plan.deprovisioned ? 'This plan is active' : 'This plan is inactive'}>
                <Box
                  key={plan.planId}
                  style={{ height: '50px' }}
                  justify='between'
                  pad='none'
                  border={index === 0 ? false : 'top'}
                  responsive={false}
                  direction='row'
                  align='center'
                >
                  <Box direction='row' justify='between' flex={true} align='center'>
                    <Box direction='row' gap='small' align='center'>
                      <Box margin={{ left: 'small' }}>
                        {!plan.deprovisioned ? <StatusIcon size='small' value='ok' /> : <StatusIcon size='small' value='critical' />}
                      </Box>
                      <Box>{plan.planName}</Box>
                    </Box>
                    <Box margin={{ right: 'medium' }} align='center'>
                      {plan.deprovisioned ? this._formatDate(plan.deprovisioned) : '-'}
                    </Box>
                  </Box>
                  <Box direction='row' flex={false} align='end' justify='end'>
                    <Button
                      icon={<View />}
                      id={IDUtil.getId('LocationListEditButton', index)}
                      onClick={this._onPlanView.bind(this, index)}
                      a11yTitle={`View ${plan.name} Plan`}
                    />
                  </Box>
                </Box>
              </GLBMTooltip>
            ))
          }
        </Box>
      </Box>
    );
  }

  _onLayerClose() {
    this.setState({ layer: undefined });
  }

  _renderLayer() {
    const { layer } = this.state;
    const { viewPlanIndex } = this.state;
    let result;
    if (layer) {
      if (layer === 'viewPlan') {
        const plan = this.state.plans[viewPlanIndex];
        result = (
          <CatalogPlanDialog
            onClose={this._onLayerClose}
            heading='Plan Details'
            plan={plan}
          />
        );
      }
    }
    return result;
  }

  render() {
    return (
      <Box>
        {this._renderPlanFields()}
        {this._renderLayer()}
      </Box>
    );
  }
}

CatalogPlanList.propTypes = {
  plans: PropTypes.array.isRequired,
};

export default CatalogPlanList;
