// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToast } from '../../components/shared/toast/ToastProvider';
import { useErrorToast } from '../../components/shared/hooks';
import {
  executeGet, executePut,
} from '../api';
import { ApiContext } from '../../AppContext';

export const useAccessApproversQuery = (options) => {
  const path = useContext(ApiContext)('administration.approvers.list.path');
  return useErrorToast(useQuery({
    queryKey: ['APPROVERS'],
    queryFn: () => executeGet(path),
    ...(options || []),
  }), 'Fetch Approvers Error');
};

export const useMutationAccessApproversUpdate = (options) => {
  const queryClient = useQueryClient();
  const path = useContext(ApiContext)('administration.approvers.update.path');
  const { addToast } = useToast();
  return useMutation({
    mutationFn: payload => executePut(path, payload),
    ...(options || {}),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ['APPROVERS']
      });
      addToast({
        status: 'normal',
        title: 'Updated',
        message: 'Approvers List Successfully Updated',
      });
      options?.onSuccess?.();
    },
    onError: (data) => {
      addToast({
        status: 'critical',
        title: 'Approver was not saved',
        message: data.response.data.message,
      });
    },
  });
};
