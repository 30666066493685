// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, CheckBoxGroup, Footer
} from 'grommet';

import IDUtil from '../shared/util/IDUtil';
import { BillingType } from '../shared/constants/BillingType';
import { PurposeType } from '../shared/constants/PurposeType';
import { ServiceStatusType } from '../shared/constants/ServiceStatusType';
import GLBMLayer from '../shared/component/GLBMLayer';
import { useCompaniesQuery } from '../../core';
import GLBMFilterPropertyBox from '../shared/component/GLBMFilterPropertyBox';
import StatusLabel from '../shared/component/StatusLabel';
import TypeLabel from '../shared/component/TypeLabel';
import { BilledByType } from '../shared/constants/BilledByType';
import { DealType } from '../shared/constants/DealType';
import ServiceTypeStore from '../stores/ServiceTypeStore';

const CustomerFilter = ({
  filter: initialFilter = {},
  onClose = null,
  onChange = null,
}) => {
  const [filter, setFilter] = useState(JSON.parse(JSON.stringify(initialFilter)) || {});

  const {
    data: companyList,
  } = useCompaniesQuery();

  const onPropChange = (name, value, option) => {
    const options = value.filter(item => item !== '');

    if (option.label === 'All' || !options || options.length === 0) {
      const {
        [name]: removed,
        ...rest
      } = filter;
      setFilter({ ...rest });
    } else {
      setFilter({
        ...filter,
        [name]: options,
      });
    }
  };

  const onSubmit = () => {
    onChange(filter);
  };

  const onClear = () => {
    setFilter({});
  };

  const renderAccountStatusOptions = () => {
    const options = BillingType.enumValues.map(({
      enumKey,
      label,
      severity,
      order,
    }) => ({
      label: <StatusLabel value={severity} label={label} />,
      value: enumKey,
      id: IDUtil.getId(`AccountStatus${enumKey}`),
      order,
    }))
      .sort((a, b) => a.order - b.order);

    return (
      <CheckBoxGroup
        value={filter.accountStatus || ['']}
        id={IDUtil.getId('AccountStatusChkGroup')}
        options={[{
          label: 'All',
          value: '',
          id: IDUtil.getId('AccountStatusAll'),
        }].concat(options)}
        onChange={({
          value,
          option,
        }) => onPropChange('accountStatus', value, option)}
      />
    );
  };

  const renderPurposeOptions = () => {
    const options = PurposeType.enumValues.map(({
      enumKey,
      label,
    }) => ({
      label,
      value: enumKey,
      id: IDUtil.getId(`Purpose${label}`),
    }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <CheckBoxGroup
        id={IDUtil.getId('PurposeChkGroup')}
        value={filter.purpose || ['']}
        options={[{
          label: 'All',
          value: '',
          id: IDUtil.getId('PurposeAll'),
        }].concat(options)}
        onChange={({
          value,
          option,
        }) => onPropChange('purpose', value, option)}
      />
    );
  };

  const renderDealTypeOptions = () => {
    const options = DealType.enumValues.map(({
      enumKey,
      label,
    }) => ({
      label,
      value: enumKey,
      id: IDUtil.getId(`DealType${enumKey}`),
    }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <CheckBoxGroup
        id={IDUtil.getId('DealTypeChkGroup')}
        value={filter.dealType || ['']}
        options={[{
          label: 'All',
          value: '',
          id: IDUtil.getId('DealTypeAll'),
        }].concat(options)}
        onChange={({
          value,
          option,
        }) => onPropChange('dealType', value, option)}
      />
    );
  };

  const renderBilledByOptions = () => {
    const options = BilledByType.enumValues.map(({
      enumKey,
      label,
    }) => ({
      label,
      value: enumKey,
      id: IDUtil.getId(`BilledByType${enumKey}`),
    }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <CheckBoxGroup
        id={IDUtil.getId('BilledByChkGroup')}
        value={filter.billedBy || ['']}
        options={[{
          label: 'All',
          value: '',
          id: IDUtil.getId('BilledByTypeAll'),
        }].concat(options)}
        onChange={({
          value,
          option,
        }) => onPropChange('billedBy', value, option)}
      />
    );
  };

  const renderServiceStatusOptions = () => {
    const options = ServiceStatusType.enumValues.map(({
      enumKey,
      label,
      severity,
    }) => ({
      label: <StatusLabel value={severity} label={label} />,
      value: enumKey,
      id: IDUtil.getId(`ServiceStatus${enumKey}`),
    }));

    return (
      <CheckBoxGroup
        id={IDUtil.getId('ServiceStatusChkGroup')}
        value={filter.serviceStatus || ['']}
        options={[{
          label: 'All',
          value: '',
          id: IDUtil.getId('ServiceStatusAll'),
        }].concat(options)}
        onChange={({
          value,
          option,
        }) => onPropChange('serviceStatus', value, option)}
      />
    );
  };

  const renderCompanyOptions = () => {
    const options = companyList?.sort((a, b) => a.name?.localeCompare(b.name))
      .map(({
        id,
        name,
      }) => ({
        label: <StatusLabel value={id} label={name} />,
        value: id,
        id: IDUtil.getId(`Company${id}`),
      }));

    return (
      <CheckBoxGroup
        id={IDUtil.getId('ServiceStatusChkGroup')}
        value={filter.companyId || ['']}
        options={[{
          label: 'All',
          value: '',
          id: IDUtil.getId('CompanyAll'),
        }].concat(options)}
        onChange={({
          value,
          option,
        }) => onPropChange('companyId', value, option)}
      />
    );
  };

  const renderServiceOptions = () => {
    const options = ServiceTypeStore.getServices()
      .map(({
        label,
        type,
      }) => ({
        label: <TypeLabel value={type} label={label || type} />,
        value: type,
        id: IDUtil.getId(`Service${type}`),
      }))
      .sort((a, b) => a.label.props.label.localeCompare(b.label.props.label));

    return (
      <CheckBoxGroup
        id={IDUtil.getId('ServicesChkGroup')}
        value={filter.serviceType || ['']}
        options={[{
          label: 'All',
          value: '',
          id: IDUtil.getId('ServicesAll'),
        }].concat(options)}
        onChange={({
          value,
          option,
        }) => onPropChange('serviceType', value, option)}
      />
    );
  };

  const hasFilter = useMemo(() => Object.keys(filter).length > 0, [filter]);

  return (
    <GLBMLayer
      a11yTitle='Customers Filter'
      onEsc={() => onClose()}
      onClickOutside={() => onClose()}
      onClose={() => onClose()}
      position='right'
      full='vertical'
      title='Filter Billing Accounts'
    >
      <Box
        pad='none'
        direction='column'
        fill='vertical'
      >
        <Box flex={true} overflow='auto'>
          <GLBMFilterPropertyBox label='Billing Account Status'>
            {renderAccountStatusOptions()}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Billing Account Purpose'>
            {renderPurposeOptions()}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Billing Account Deal Type'>
            {renderDealTypeOptions()}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Billing Account Billed By'>
            {renderBilledByOptions()}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Service Status'>
            {renderServiceStatusOptions()}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Company'>
            {renderCompanyOptions()}
          </GLBMFilterPropertyBox>
          <GLBMFilterPropertyBox label='Service'>
            {renderServiceOptions()}
          </GLBMFilterPropertyBox>
        </Box>
      </Box>
      <Box border='top' pad='small' margin={{ top: 'none' }} flex={false}>
        <Footer flex={false} justify='between'>
          <Box justify='start' gap='small' direction='row'>
            <Button
              label='Apply'
              type='button'
              id={IDUtil.getId('ApplyBtn')}
              primary={true}
              onClick={onSubmit}
            />
            <Button
              label='Cancel'
              id={IDUtil.getId('CancelBtn')}
              type='button'
              secondary={true}
              onClick={onClose}
            />
          </Box>
          <Button
            label='Clear Filters'
            id={IDUtil.getId('ResetBtn')}
            onClick={hasFilter ? onClear : undefined}
          />
        </Footer>
      </Box>
    </GLBMLayer>
  );
};

CustomerFilter.propTypes = {
  filter: PropTypes.object,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
};

export default CustomerFilter;
