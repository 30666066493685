// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import React, { useMemo, useState } from 'react';
import { Box, Button } from 'grommet';
import { FolderOpen, Refresh, Search } from 'grommet-icons';
import parser from 'cron-parser';
import GLBMDataSummary from '../../shared/component/GLBMDataSummary';
import GLBMSearch from '../../shared/component/GLBMSearch';
import IDUtil from '../../shared/util/IDUtil';
import GLBMHeading from '../../shared/component/GLBMHeading';
import GLBMDataTable from '../../shared/component/GLBMDataTable';
import { isDev } from '../../shared/environment/EnvironmentUtil';
import {
  useGetPendingFilesQuery,
  useGetSchedulePendingFilesQuery,
  useSyncScheduleMutation,
} from '../../../core';
import ConfirmationDialog from '../../shared/dialogs/ConfirmationDialog';
import { useColumns, useWarningFiles } from './handler';

const PendingFiles = () => {
  const [searchText, setSearchText] = useState('');
  const [layer, setLayer] = useState('');
  const [sort, setSort] = useState({
    property: 'fileName',
    direction: 'asc',
  });

  const {
    data: pendingFilesData,
    refetch: refreshPendingFiles,
    isFetching: loadingPendingFiles,
  } = useGetPendingFilesQuery();

  const {
    data: cronResults,
  } = useGetSchedulePendingFilesQuery();

  const onLayerClose = () => {
    setLayer('');
  };
  const {
    mutate: updatePendingFiles,
  } = useSyncScheduleMutation({
    onSuccess: () => onLayerClose(),
  });

  const list = useMemo(() => (pendingFilesData ? pendingFilesData.map((el, i) => ({
    ...el,
    index: i,
  })) : []), [pendingFilesData]);

  const filteredList = useMemo(() => (
    list && searchText !== undefined
      ? list.filter(item => (
          item?.fileName?.toLowerCase()
            .includes(searchText.toLowerCase())) || item?.serviceType?.toLowerCase()
          .includes(searchText.toLowerCase())
        || item?.accountId?.toLowerCase()
          .includes(searchText.toLowerCase())
        || item?.sizeInBytes?.toString()
          .includes(searchText.toLowerCase())
        || item?.lastModified?.toLowerCase()
          .includes(searchText.toLowerCase()))
      : list), [list, searchText]);

  // sort the filteredList based on the sort property
  const sortedList = useMemo(() => {
    if (filteredList) {
      const {
        property,
        direction,
      } = sort || {};
      return [...filteredList].sort((a, b) => {
        if (a[property] < b[property]) {
          return direction === 'asc' ? -1 : 1;
        }
        if (a[property] > b[property]) {
          return direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return [];
  }, [filteredList, sort]);

  const warningFiles = useWarningFiles(pendingFilesData);
  const {
    columns,
    layers,
  } = useColumns(searchText, warningFiles);

  let intervals;
  try {
    if (cronResults) {
      const interval = parser.parseExpression(cronResults.cron);
      intervals = interval.next()
        .toString();
    }
  } catch (err) {
    console.error(err);
  }
  const renderLayer = () => {
    let r;
    if (layer === 'open') {
      r = (
        <ConfirmationDialog
          title='Confirm Processing Pending Files'
          submitLabel='Confirm'
          cancelLabel='Cancel'
          text='Are you sure you want to Process the Pending Files?'
          onClose={onLayerClose}
          onChange={updatePendingFiles}
        />
      );
      return r;
    }
    return '';
  };
  const changeLayer = () => {
    setLayer('open');
  };

  const glbmActions = [
    (<Button
      kind='toolbar'
      icon={<Refresh />}
      key={IDUtil.getId('PendingFilesRefreshButton')}
      onClick={refreshPendingFiles}
      a11yTitle='Refresh Pending File List'
      id={IDUtil.getId('PendingFilesRefreshButton')}
      tip='Refresh'
      label='Refresh'
      busy={loadingPendingFiles}
    />)];
  if (isDev()) {
    glbmActions.push((
      <Button
        kind='toolbar'
        icon={<FolderOpen />}
        key={IDUtil.getId('PendingFilesButton')}
        onClick={() => {
          changeLayer();
        }}
        id={IDUtil.getId('PendingFilesButton')}
        tip='Process pending files now'
        label='Process Pending Files'
      />
    ));
  }
  return (
    <Box direction='column' fill='vertical'>
      <Box
        margin={{
        top: '20px',
        left: '15px',
      }}
        flex={false}
      >
        <span>
          <strong>Pending Files will be processed : </strong>
          {intervals}
        </span>
      </Box>
      <GLBMHeading
        search={(
          <GLBMSearch
            id={IDUtil.getId('PendingFilesListSearchInput')}
            placeholder='Search'
            icon={<Search />}
            value={searchText}
            onChange={e => setSearchText(e)}
          />
        )}
        actions={glbmActions}
      />
      <GLBMDataSummary
        total={pendingFilesData?.length}
        filtered={filteredList?.length}
      />
      <GLBMDataTable
        searchText={searchText}
        columns={columns}
        onSort={setSort}
        sort={sort}
        data={loadingPendingFiles ? [] : sortedList}
        total={filteredList.length}
        loading={loadingPendingFiles}
        primaryKey='fileName'
      />
      {renderLayer()}
      {layers}
    </Box>
  );
};

export default PendingFiles;
