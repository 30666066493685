// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { createContext, useContext } from 'react';
import includes from 'lodash/includes';
import { isFunction } from 'lodash/fp';
import { getASMCustomerRole } from '../../../shared/util/asmCustomerRole';
import { pagePermissions, roles } from '../../../shared/constants/Permissions';
import { PartnerType } from '../../../shared/constants/PartnerType';
import { ServiceValidator } from '../../validation/ServiceValidator';

export const ServiceEditorContext = createContext([undefined, () => { }]);

export const getNewPermissions = (state, action) => {
  const {
    user, isOneOfRoles, customer, hasPermissions, serviceId, tenant,
  } = action;

  const asmRole = getASMCustomerRole(user, customer);
  const checkAsmRole = (...neededRoles) => {
    if (isOneOfRoles([roles.SERVICE_DEV, roles.ASM])) {
      return includes(neededRoles, asmRole);
    }
    return true;
  };

  const isResellerTenant = includes([PartnerType.RESELLER.enumKey, PartnerType.DISTRIBUTOR.enumKey], tenant);
  const isSuperAndResellerTenant = isOneOfRoles([roles.SUPER]) && isResellerTenant;

  const result = {
    ...state.permissions,
    userType: user.role,
    asmRole,

    canReadRates: hasPermissions(isResellerTenant ? pagePermissions.customers.view.services.steps.readMarkupRates : pagePermissions.customers.view.services.steps.readRates)
      && checkAsmRole('READ', 'EDIT', 'SUPER_EDIT'),
    canEditRates: hasPermissions(pagePermissions.customers.view.services.steps.editRates)
      && checkAsmRole('EDIT', 'SUPER_EDIT')
      && !isSuperAndResellerTenant,
    canMarkupRates: hasPermissions(pagePermissions.customers.view.services.steps.markupRates)
      && isSuperAndResellerTenant,
    canReadOptions: hasPermissions(pagePermissions.customers.view.services.steps.readOptions)
      && checkAsmRole('SUPER_EDIT')
      && !isSuperAndResellerTenant,
    canEditOptions: hasPermissions(pagePermissions.customers.view.services.steps.editOptions)
      && checkAsmRole('SUPER_EDIT')
      && !isSuperAndResellerTenant,
    canReadMappings: hasPermissions(pagePermissions.customers.view.services.steps.readMappings)
      && checkAsmRole('SUPER_EDIT')
      && !isSuperAndResellerTenant,
    canEditMappings: hasPermissions(pagePermissions.customers.view.services.steps.editMappings)
      && checkAsmRole('SUPER_EDIT')
      && !isSuperAndResellerTenant,
  };

  // certain services have extra options:
  if (serviceId === 'AZURE_STACK') {
    result.canEditRates = false;
  }
  return result;
};

export const useServiceEditorContext = () => useContext(ServiceEditorContext);

export const useValidationContext = () => {
  const [_, setServiceEditor] = useServiceEditorContext();
  const setValidation = (validation) => {
    if (isFunction(validation)) {
      setServiceEditor(s => ({ ...s, validation: validation(s.validation) }));
      return;
    }
    setServiceEditor(s => ({ ...s, validation }));
  };
  const invalidateValidation = () => {
    setValidation(validation => ({ ...validation, didInvalidate: true }));
  };
  const receiveValidation = (step, state, customerId, serviceType, json, error) => {
    // TODO:::: NEEDS TO BE REFACTORED WHEN UPGRADING TO NEW VALIDATE ENDPOINT
    const results = (json ? (json.unconfiguredService || json.unconfiguredDetails) : undefined);
    const {
      customer, options, equipment, meters, rates, locationRates, context
    } = state;
    const properties = ServiceValidator.validate(step, results, customer, options, equipment, meters, rates, locationRates, context);

    setValidation(validation => ({
      ...validation,
      isFetching: false,
      didInvalidate: false,
      results,
      errors: (error ? error.message : undefined),
      properties
    }));
  };

  return { invalidateValidation, receiveValidation };
};
