// (C) Copyright 2017-2025 Hewlett Packard Enterprise Development LP

import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { createIntl, defineMessages } from 'react-intl';
import { axiosConfig } from '../utils';
import URLUtil from './utils';

const apiBasePath = () => `${window.APIURL}/fc/api/definitions`;

/**
 * A little crude - but it loops through the returned object, and puts
 * the domain root path in the front of each and every 'path' found.
 * @param obj
 * @returns {*}
 */
const changePath = (obj) => {
  Object.keys(obj).forEach((key) => {
    // eslint-disable-next-line no-param-reassign
    obj[key] = `${window.APIURL}${obj[key]}`;
  });
  return obj;
};

const translatePath = (intl, data, path, values, query, whiteList, blackList) => {
  const msg = intl.formatMessage({ id: path }, values);
  return `${msg}${query ? URLUtil.objectToQueryParams(query, whiteList, blackList) : ''}`;
};

/**
 * Function to get api defs object - but also for now intercepting and replacing with UI temp object.
 * @returns {Promise<any>}
 */
const fetchApiDefs = async () => {
  const { data } = await axios.get(apiBasePath(), {
    ...axiosConfig,
    transformResponse: axios.defaults.transformResponse.concat(_apiDef => changePath(cloneDeep(_apiDef))),
  });
  return data;
};

/**
 * This query will return back the query itself and also the callback...
 * TODO: THIS WILL BE MOVED TO /query/ folder when ready from backend.
 * @param options
 * @returns {{errorUpdateCount: number, data: any, refetch: <TPageData>(options?: (RefetchOptions & RefetchQueryFilters<TPageData>)) => Promise<QueryObserverResult<any, unknown>>, error: unknown, remove: () => void, apiCallback: (function(*, {}=): string), isFetchedAfterMount: boolean, isError: true | false, isFetched: boolean, isSuccess: false | true, isLoadingError: false | true, isInitialLoading: boolean, isPaused: boolean, fetchStatus: FetchStatus, isRefetching: boolean, isRefetchError: true | false, isFetching: boolean, isPlaceholderData: boolean, isLoading: false | true, errorUpdatedAt: number, dataUpdatedAt: number, isPreviousData: boolean, failureReason: unknown, isStale: boolean, failureCount: number, status: "error" | "success" | "loading"}}
 */
export const useApiDefsQuery = (options = {}) => {
  const query = useQuery({
    queryKey: ['GLBM:API-DEFS'],
    queryFn: () => fetchApiDefs(),
    enabled: !!window.APIURL,
    ...options,
  });

  const messages = useMemo(() => {
    if (query.isSuccess) {
      return defineMessages(query.data);
    }
    return {};
  }, [query]);

  const intl = useMemo(() => createIntl({
    locale: 'en',
    messages,
  }), [messages, query]);

  /**
   * This callback function is what we will access in useCustomerQuery etc... to
   * resolve the path we need with transposed in variables.
   *
   * Example: if passing in path = '/fc/customers/{customerId}' with values = { customerId = '123'}
   * then the end result will be /fc/customers/123.
   * @type {function(*, {}=): string}
   */
  const apiCallback = useCallback((path, values = {}, queryParams = undefined, whiteList = undefined, blackList = undefined) => translatePath(intl, query.data, path, values, queryParams, whiteList, blackList), [query, intl]);

  // return the query + callback:
  return {
    ...query,
    apiCallback,
  };
};
